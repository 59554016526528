import React from 'react'
import { useFormik } from 'formik'
import { Form, Modal, Button } from 'react-bootstrap'

const requiredFields = ['descr', 'city']
const fieldLengths = [
  {
    name: 'descr',
    length: 255
  },
  {
    name: 'city',
    length: 255
  }
]

export default function TmimaModalForm({ show, tmima, onSubmit, onCancel }) {

  // ---------------------------------------------------------------
  // Reset form
  // ---------------------------------------------------------------
  React.useEffect(() => {
    if (!show) {
      formik.resetForm() // reset on close
    }
  }, [show])

  // ---------------------------------------------------------------
  // Form validation
  // ---------------------------------------------------------------
  const validateForm = values => {

    const errors = {}

    // Check for required fields
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = 'Υποχρεωτικό πεδίο'
      }
    })

    // Check for field lengths
    fieldLengths.forEach(field => {
      if (values[field.name] && values[field.name].length > field.length) {
        errors[field.name] = `Πληκτρολογείστε μέχρι ${field.length} χαρακτήρες`
      }
    })

    return errors;
  }

  // ---------------------------------------------------------------
  // Initial form values
  // ---------------------------------------------------------------
  const initialValues = {
    id: tmima?.id || -1,
    descr: tmima?.descr || '',
    city: tmima?.city || ''
  }

  // ---------------------------------------------------------------
  // Formik hook
  // ---------------------------------------------------------------
  const formik = useFormik({
    initialValues,
    onSubmit: onSubmit,
    validate: validateForm,
    enableReinitialize: true,
  })

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <Modal show={show} backdrop="static" keyboard={false} size="lg">
      <Modal.Header>
        <Modal.Title>{tmima ? "Επεξεργασία Τμήματος" : "Νέο Τμήμα"}</Modal.Title>
      </Modal.Header >
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <DescrField formik={formik} />
          <CityField formik={formik} />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={formik.handleSubmit}>Καταχώρηση</Button>
        <Button variant="danger" onClick={onCancel}>Ακύρωση</Button>
      </Modal.Footer>
    </Modal>
  )
}

// ********************************************************************************
// Field: descr
// ********************************************************************************
function DescrField({ formik }) {
  return (
    <Form.Group className="mb-3" controlId="descr">
      <Form.Label>ΠΕΡΙΓΡΑΦΗ ΤΜΗΜΑΤΟΣ (*)</Form.Label>
      <Form.Control
        name="descr"
        type="text"
        value={formik.values.descr || ''}
        onChange={formik.handleChange}
        isInvalid={formik.touched.descr && formik.errors.descr}
      />
      <Form.Control.Feedback type="invalid">
        {formik.errors.descr}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

// ********************************************************************************
// Field: city
// ********************************************************************************
function CityField({ formik }) {
  return (
    <Form.Group className="mb-3" controlId="city">
      <Form.Label>ΠΟΛΗ (*)</Form.Label>
      <Form.Control
        name="city"
        type="text"
        value={formik.values.city || ''}
        onChange={formik.handleChange}
        isInvalid={formik.touched.city && formik.errors.city}
      />
      <Form.Control.Feedback type="invalid">
        {formik.errors.city}
      </Form.Control.Feedback>
    </Form.Group>
  )
}