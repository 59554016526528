import React from 'react'
import ReactDOM from 'react-dom/client'
import { Container, Button } from 'react-bootstrap'
import { createUserApi, fetchUsersApi } from '../../../../api/users'
import DataTable from 'react-data-table-component'
import { formatDateTime } from '../../../../utils'
import StatusIcon from './StatusIcon'
import UserModalForm from './UserModalForm'
import useLoader from '../../../../hooks/useLoader'


// Datatabble columns
const columns = [
  {
    name: 'Ενεργός',
    selector: row => row.isactive,
    format: row => <StatusIcon user={row} />,
    sortable: true,
    sortFunction: (a, b) => a.isactive > b.isactive,
    width: '100px'
  },
  {
    name: 'Ονοματεπώνυμο',
    selector: row => row.fullname,
    sortable: true
  },
  {
    name: 'E-mail',
    selector: row => row.email,
    sortable: true
  },
  {
    name: 'Ρόλοι',
    selector: row => row.roles,
    sortable: true
  },
  {
    name: 'Ενημέρωση',
    selector: row => row.updated_at,
    format: row => formatDateTime(row.updated_at),
    sortable: true,
  }

]


export default function Index() {

  // ---------------------------------------------------------------
  // Local state and context
  // ---------------------------------------------------------------
  const [users, setUsers] = React.useState([])
  const [showForm, setShowForm] = React.useState(false)
  const { loader: pageLoader, startLoader: startPageLoader, stopLoader: stopPageLoader } = useLoader()

  // ---------------------------------------------------------------
  // Fetch users on mount
  // ---------------------------------------------------------------
  React.useEffect(() => {
    startPageLoader()
    fetchUsersApi()
      .then(data => {
        setUsers(data)
        stopPageLoader()
      })
      .catch(error => {
        console.log(error)
        stopPageLoader()
      })
  }, [])

  // ---------------------------------------------------------------
  // handle submit new user
  // ---------------------------------------------------------------
  const handleCreate = data => {
    createUserApi(data)
      .then(newUser => {
        setUsers(users => [...users, newUser])
      })
      .catch(error => console.log(error))

    setShowForm(false)
  }

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <Container className="mt-3 mb-5">
      {
        pageLoader ||
        <div className="card">
          <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h4 className="card-title">
              Χρήστες Εφαρμογής
            </h4>
            <Button size="sm" variant="success" onClick={() => setShowForm(true)}>Νέος Χρήστης</Button>
          </div>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={users}
              defaultSortFieldId={2}
              defaultSortAsc={true}
              pagination
              paginationComponentOptions={{
                rowsPerPageText: 'Χρήστες ανά σελίδα',
                rangeSeparatorText: 'από',
                selectAllRowsItem: true,
                selectAllRowsItemText: 'Όλα',
              }}
              theme='default'
              noDataComponent="Δεν υπάρχουν καταχωρημένοι χρήστες"
              highlightOnHover
              pointerOnHover
              onRowClicked={row => window.location.href = `/admin/users/${row.id}`}
            />
          </div>
          <UserModalForm
            show={showForm}
            user={{ id: -1, isactive: true }}
            onSubmit={handleCreate}
            onCancel={() => setShowForm(false)}
          />
        </div>
      }
    </Container>
  )
}


// ---------------------------------------------------------------
// Mount into dom
// ---------------------------------------------------------------
if (document.getElementById('admin-users-index')) {
  const root = ReactDOM.createRoot(document.getElementById('admin-users-index'))
  root.render(<Index />)
}
