import React from 'react'
import { Button } from "react-bootstrap"
import { deleteInvitationApi, updateInvitationApi } from "../../../../api/invitations"
import { getConfirm } from "../../../../components/Confirm"
import InvitationModalForm from '../index/InvitationModalForm'
import { formatDate, formatDateTime } from '../../../../utils'
import { styles } from '../../../../components/styles'

export default function Stoixeia({ invitation, setInvitation }) {

    // ---------------------------------------------------------------
    // Local state and context
    // ---------------------------------------------------------------
    const [showForm, setShowForm] = React.useState(false)

    // ---------------------------------------------------------------
    // handle edit
    // ---------------------------------------------------------------
    const handleEdit = (values) => {
        setShowForm(false)
        updateInvitationApi(values)
            .then(response => setInvitation(response))
            .catch(error => console.log(error))
    }

    // ---------------------------------------------------------------
    // handle delete
    // ---------------------------------------------------------------
    const handleDelete = () => {
        getConfirm(
            'Διαγραφή πρόσκλησης',
            'Να διαγραφεί η επιλεγμένη πρόσκληση;',
            "Διαγραφή",
            'Ακύρωση',
        ).then(response => {
            if (response) {
                deleteInvitationApi(invitation)
                    .then(response => {
                        window.location.href = `/admin/invitations`
                    })
                    .catch(error => console.log(error))
            }
        })
    }

    // ---------------------------------------------------------------
    // JSX
    // ---------------------------------------------------------------
    return (
        <div className="card mt-4">
            <div className="card-header" style={{ display: "flex", justifyContent: 'space-between' }}>
                <h5 className="card-title">Στοιχεία Πρόσκλησης</h5>
            </div>
            <div className="card-body">

                <div style={styles.section}>
                    <div style={styles.label}>ΤΥΠΟΣ ΠΡΟΣΚΛΗΣΗΣ</div>
                    <div style={styles.data}>{invitation?.type_descr}</div>
                </div>

                <div style={styles.section}>
                    <div style={styles.label}>ΠΕΡΙΓΡΑΦΗ</div>
                    <div style={styles.data}>{invitation?.descr}</div>
                </div>

                <div style={styles.section}>
                    <div style={styles.label}>ΑΡΙΘΜΟΣ ΠΡΩΤΟΚΟΛΛΟΥ</div>
                    <div style={styles.data}>{invitation?.prot_aa} - {formatDate(invitation?.prot_date)}</div>
                </div>

                <div style={styles.section}>
                    <div style={styles.label}>ΠΡΟΘΕΣΜΙΑ ΥΠΟΒΟΛΗΣ ΠΡΟΤΑΣΕΩΝ</div>
                    <div style={styles.data}>{formatDateTime(invitation?.deadline)}</div>
                </div>
            </div>

            <div className="card-footer" style={{ display: 'flex', gap: '15px' }}>

                <Button variant='primary' onClick={() => setShowForm(true)} >
                    Επεξεργασία Στοιχείων Πρόσκλησης
                </Button>

                {/* Αν δεν υπάρχουν αιτήσεις μπορεί να γίνει διαγραφή */}
                {
                    invitation?.applications.length == 0
                        ?
                        <Button size='sm' variant='danger' onClick={handleDelete}>Διαγραφή Πρόσκλησης</Button>
                        :
                        null
                }
            </div>

            {/* Φόρμα επεξεργασίας (modal) */}
            <InvitationModalForm
                show={showForm}
                invitation={invitation}
                onSubmit={handleEdit}
                onCancel={() => setShowForm(false)}
            />
        </div>
    )
}