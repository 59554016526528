import React from 'react'
import { countAppDocs, formatDate, formatDateTime } from '../utils'

// Styles
const styles = {
    section: {
        marginBottom: '20px',
    },
    caption: {
        fontSize: '20px',
        color: '#429325'
    },
    dataGroup: {
        display: 'flex',
        gap: '10px'
    },
    label: {
        fontWeight: 700
    },
    data: {
        fontSize: '16px'
    }
}

export default function ApplicationPreview({ application }) {

    // ---------------------------------------------------------------
    // JSX
    // ---------------------------------------------------------------
    return (
        <div>
            {/* Στοιχεία αίτησης */}
            <div style={styles.section}>
                <h4 style={styles.caption}>Στοιχεία Αίτησης</h4>
                <div style={styles.dataGroup}>
                    <span style={styles.label}>ΚΑΤΑΣΤΑΣΗ:</span>
                    <span style={styles.data}>{application?.status_descr}</span>
                </div>
                {
                    application?.submitted_at ?
                        <div style={styles.dataGroup}>
                            <span style={styles.label}>ΤΕΛΕΥΤΑΙΑ ΥΠΟΒΟΛΗ:</span>
                            <span style={styles.data}>{formatDateTime(application?.submitted_at)}</span>
                        </div>
                        : null
                }
                {
                    application?.prot_aa ?
                        <div style={styles.dataGroup}>
                            <span style={styles.label}>ΑΡΙΘΜΟΣ ΠΡΩΤΟΚΟΛΛΟΥ:</span>
                            <span style={styles.data}>{application?.prot_aa}</span>
                        </div>
                        : null
                }
                {
                    application?.prot_date ?
                        <div style={styles.dataGroup}>
                            <span style={styles.label}>ΗΜ/ΝΙΑ ΠΡΩΤΟΚΟΛΛΟΥ:</span>
                            <span style={styles.data}>{formatDate(application?.prot_date)}</span>
                        </div>
                        : null
                }

            </div>

            {/* Επιλεγμένη θέση */}
            <div style={styles.section}>
                <h4 style={styles.caption}>Επιλεγμένη Θέση</h4>
                {
                    application?.position?.tmima ?
                        <>
                            <div style={styles.dataGroup}>
                                <span style={styles.label}>ΣΧΟΛΗ:</span>
                                <span style={styles.data}>{application?.position?.tmima.sxoli.descr}</span>
                            </div>
                            <div style={styles.dataGroup}>
                                <span style={styles.label}>ΤΜΗΜΑ:</span>
                                <span style={styles.data}>{application?.position?.tmima.descr} ({application?.position?.tmima.city})</span>
                            </div>
                        </>
                        : null
                }
                {
                    application?.position?.pedio ?
                        <div style={styles.dataGroup}>
                            <span style={styles.label}>ΕΠΙΣΤΗΜΟΝΙΚΟ ΠΕΔΙΟ:</span>
                            <span style={styles.data}>{application?.position?.pedio}</span>
                        </div>
                        : null
                }
                {
                    application?.position?.descr ?
                        <div style={styles.dataGroup}>
                            <span style={styles.label}>ΠΕΡΙΓΡΑΦΗ:</span>
                            <span style={styles.data}>{application?.position?.descr}</span>
                        </div>
                        : null
                }
            </div>

            {/* Προσωπικά στοιχεία */}
            <div style={styles.section}>
                <h4 style={styles.caption}>Προσωπικά Στοιχεία</h4>
                <div style={styles.dataGroup}>
                    <span style={styles.label}>ΕΠΩΝΥΜΟ:</span>
                    <span style={styles.data}>{application?.personal.surname}</span>
                </div>
                <div style={styles.dataGroup}>
                    <span style={styles.label}>ΟΝΟΜΑ:</span>
                    <span style={styles.data}>{application?.personal.firstname}</span>
                </div>
                <div style={styles.dataGroup}>
                    <span style={styles.label}>ΠΑΤΡΩΝΥΜΟ:</span>
                    <span style={styles.data}>{application?.personal.fathername}</span>
                </div>
                <div style={styles.dataGroup}>
                    <span style={styles.label}>ΕΠΑΓΓΕΛΜΑ/ΙΔΙΟΤΗΤΑ:</span>
                    <span style={styles.data}>{application?.personal.job}</span>
                </div>
                <div style={styles.dataGroup}>
                    <span style={styles.label}>Α.Φ.Μ.:</span>
                    <span style={styles.data}>{application?.personal.afm}</span>
                </div>
                <div style={styles.dataGroup}>
                    <span style={styles.label}>Α.Δ.Τ.:</span>
                    <span style={styles.data}>{application?.personal.adt}</span>
                </div>
                <div style={styles.dataGroup}>
                    <span style={styles.label}>ΗΜΕΡΟΜΗΝΙΑ ΓΕΝΝΗΣΗΣ:</span>
                    <span style={styles.data}>{formatDate(application?.personal.birthdate)}</span>
                </div>
                <div style={styles.dataGroup}>
                    <span style={styles.label}>ΥΠΗΚΟΟΤΗΤΑ:</span>
                    <span style={styles.data}>{application?.personal.nationality}</span>
                </div>
            </div>

            {/* Σοιχεία επικοινωνίας */}
            <div style={styles.section}>
                <h4 style={styles.caption}>Στοιχεία Επικοινωνίας</h4>
                <div style={styles.dataGroup}>
                    <span style={styles.label}>ΟΔΟΣ ΚΑΙ ΑΡΙΘΜΟΣ:</span>
                    <span style={styles.data}>{application?.contact.address}</span>
                </div>
                <div style={styles.dataGroup}>
                    <span style={styles.label}>ΠΟΛΗ/ΠΕΡΙΦΕΡΕΙΑ:</span>
                    <span style={styles.data}>{application?.contact.city}</span>
                </div>
                <div style={styles.dataGroup}>
                    <span style={styles.label}>Τ.Κ.:</span>
                    <span style={styles.data}>{application?.contact.tk}</span>
                </div>
                <div style={styles.dataGroup}>
                    <span style={styles.label}>ΤΗΛΕΦΩΝΟ ΟΙΚΙΑΣ:</span>
                    <span style={styles.data}>{application?.contact.phone_home}</span>
                </div>
                <div style={styles.dataGroup}>
                    <span style={styles.label}>ΤΗΛΕΦΩΝΟ ΕΡΓΑΣΙΑΣ:</span>
                    <span style={styles.data}>{application?.contact.phone_work}</span>
                </div>
                <div style={styles.dataGroup}>
                    <span style={styles.label}>ΚΙΝΗΤΟ ΤΗΛΕΦΩΝΟ:</span>
                    <span style={styles.data}>{application?.contact.mobil}</span>
                </div>
                <div style={styles.dataGroup}>
                    <span style={styles.label}>ΟΝΟΜΑΤΕΠΩΝΥΜΟ ΕΚΠΡΟΣΩΠΟΥ:</span>
                    <span style={styles.data}>{application?.contact.agent_name}</span>
                </div>
                <div style={styles.dataGroup}>
                    <span style={styles.label}>ΤΗΛΕΦΩΝΟ ΕΚΠΡΟΣΩΠΟΥ:</span>
                    <span style={styles.data}>{application?.contact.agent_phone}</span>
                </div>
            </div>

            {/* Δικαιολογητικά */}
            <DocumentsSection application={application} />

            {/* Λοιπά αρχεία */}
            <FilesSection application={application} />

        </div >
    )
}


// ----------------------------------------------------------------------------------------------
// Documents section
// ---------------------------------------------------------------------------------------------- 
function DocumentsSection({ application }) {

    const { required, submitted } = countAppDocs(application?.documents)
    if (submitted == 0) return null

    return (
        <div style={styles.section}>
            <h4 style={styles.caption}>Δικαιολογητικά</h4>
            {
                application?.documents.map(doc => {
                    return (
                        <div key={doc.id} style={styles.dataGroup}>
                            {
                                doc.url
                                    ?
                                    <a href={doc.url} target="_blank">{doc.document.descr}</a>
                                    :
                                    null
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

// ----------------------------------------------------------------------------------------------
// Files section
// ---------------------------------------------------------------------------------------------- 
function FilesSection({ application }) {
    if (application?.files.length == 0) return null

    return (
        < div style={styles.section} >
            <h4 style={styles.caption}>Λοιπά Αρχεία</h4>
            {
                application?.files.map(file => {
                    return (
                        <div key={file.id} style={styles.dataGroup}>
                            {
                                file.url
                                    ?
                                    <a href={file.url} target="_blank">{file.descr}</a>
                                    :
                                    null
                            }
                        </div>
                    )
                })
            }
        </div >
    )
}
