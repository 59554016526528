import React from 'react'
import ReactDOM from 'react-dom/client'
import { Container } from 'react-bootstrap'
import { fetchInvitationApi } from '../../../../api/invitations'
import InvitationHeader from '../../../../components/InvitationHeader'
import { fetchConnectedUserApi } from '../../../../api/auth'
import AdminView from './AdminView'
import SupervisorView from './SupervisorView'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import useLoader from '../../../../hooks/useLoader'



export default function Show() {

  // ---------------------------------------------------------------
  // Local state and context
  // ---------------------------------------------------------------
  const [user, setUser] = React.useState()
  const [invitation, setInvitation] = React.useState()
  const { loader: pageLoader, startLoader: startPageLoader, stopLoader: stopPageLoader } = useLoader()

  // ---------------------------------------------------------------
  // Get connected user and invitation on mount
  // ---------------------------------------------------------------
  React.useEffect(() => {

    startPageLoader()

    const url = window.location.href.split('/')
    const invitationsId = url[url.length - 1]

    // Fetch connected user (TODO: after layout - global context)
    fetchConnectedUserApi()
      .then(response => setUser(response))
      .catch(error => console.log(error))

    // Fetch Invitation
    fetchInvitationApi(invitationsId)
      .then(response => {
        setInvitation(response)
        stopPageLoader()
      })
      .catch(error => {
        console.log(error)
        stopPageLoader()
      })

  }, [])

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <Container className="mt-3 mb-5">
      {pageLoader ||
        <div>
          <ToastContainer position={toast.POSITION.TOP_RIGHT} theme="colored" />
          <InvitationHeader invitation={invitation} />
          {
            user?.is_admin || user?.is_editor
              ? <AdminView invitation={invitation} setInvitation={setInvitation} />
              : <SupervisorView invitation={invitation} setInvitation={setInvitation} />
          }
        </div>
      }
    </Container>
  )

}


// ---------------------------------------------------------------
// Mount into dom
// ---------------------------------------------------------------
if (document.getElementById('admin-invitations-show')) {
  const root = ReactDOM.createRoot(document.getElementById('admin-invitations-show'))
  root.render(<Show />)
}