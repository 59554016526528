export default function InvitationFiles({ files }) {
  return (
    <div>
      {files?.map(file => {
        return (
          <div key={file.id} className="mb-2">
            <a
              href={file.url} target="_blank">{file.descr} ({file.extension}) - {file.size}
            </a>
          </div>
        )
      })
      }
    </div>
  )
}