import React from 'react'
import DataTable from 'react-data-table-component'
import { formatDate } from '../../../../utils'


// Datatabble columns
const columns = [
  {
    name: 'Αρ. πρωτ.',
    selector: row => row.invitation.prot_aa,
    sortable: true,
    wrap: true,
    maxWidth: '150px'
  },
  {
    name: 'Περιγραφή Πρόσκλησης',
    selector: row => row.invitation.descr,
    sortable: true,
    wrap: true
  },
  {
    name: 'Θέση',
    selector: row => row.position.pedio ? row.position.pedio : row.position.descr,
    sortable: true,
    wrap: true
  },
  {
    name: 'Κατάσταση',
    selector: row => row.status_descr,
    sortable: true,
    wrap: true,
    maxWidth: '150px'
  },
  {
    name: 'Υποβολή',
    selector: row => formatDate(row.submitted_at),
    sortable: true,
    wrap: true,
    maxWidth: '150px'
  },
]

export default function Applications({ user }) {
  return (
    <div className="card mt-4">
      <div className="card-header" style={{ display: "flex", justifyContent: 'space-between' }}>
        <h5 className="card-title">Αιτήσεις</h5>
      </div>
      <div className="card-body">
        <DataTable
          columns={columns}
          data={user.applications}
          defaultSortFieldId={5}
          defaultSortAsc={false}
          pagination
          paginationComponentOptions={{
            rowsPerPageText: 'Εγγραφές ανά σελίδα',
            rangeSeparatorText: 'από',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Όλα',
          }}
          theme='default'
          noDataComponent="Δεν υπάρχουν δεδομένα"
          highlightOnHover
        />
      </div>
    </div>
  )
}
