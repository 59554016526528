import React from 'react'
import { Card, Table, Button } from 'react-bootstrap'
import { checkApplicationExistsApi } from '../../../api/applications'
import { getConfirm } from '../../Confirm'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// --------------------------------------------------------------------------------------------------------------------
// Invitation positions of type 1
// props: 
// - positions
// --------------------------------------------------------------------------------------------------------------------
export default function InvitationTypeOne({ positions, user }) {

  const sxoles = [] // Rendered sxoles

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <div>
      <h2 style={{ textAlign: 'center' }}>Θέσεις</h2>
      {
        positions.map(position => {
          if (!sxoles.includes(position.tmima.sxoli.id)) {
            sxoles.push(position.tmima.sxoli.id)
            return <RenderSxoli key={position.tmima.sxoli.id} sxoli={position.tmima.sxoli} positions={positions} />
          }
          else {
            return null
          }
        })
      }
    </div>
  )
}


// Εκτύπωση σχολής
/*
  sxoli
  positions
*/
function RenderSxoli({ sxoli, positions }) {

  const tmimata = []  // rendered tmimata

  return (
    <div>
      <h4 style={{ color: '#093FA9' }} className="mt-4">{sxoli.descr}</h4>
      {
        positions.map(position => {
          if (position.tmima.sxoli.id == sxoli.id && !tmimata.includes(position.tmima.id)) {
            tmimata.push(position.tmima.id)
            return <RenderTmima key={position.tmima.id} tmima={position.tmima} positions={positions} />
          }
          else {
            return null
          }
        })
      }
    </div>
  )
}

// Εκτύπωση τμήματος
/*
  tmima
  positions
*/
function RenderTmima({ tmima, positions }) {

  // Handle create application
  const handleCreateApplication = position => {

    // Έλεγχος αν υπάρχει ήδη αίτηση για τη θέση από τον συνδεδεμένο χρήστη
    checkApplicationExistsApi(position)
      .then(response => {
        if (response.exist) {
          toast.error('Υπάρχει ήδη καταχωρημένη αίτηση για την επιλεγμένη θέση')
        }
        else {
          getConfirm(
            'Δημιουργία νέας αίτησης',
            'Να δημιουργηθεί μία νέα αίτηση για την επιλεγμένη θέση;',
            "Δημιουργία",
            'Ακύρωση',
          ).then(response => {
            if (response) {
              window.location.href = `/applications/create/${position.id}`
            }
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  // JSX
  return (
    <Card className="mt-4">
      <Card.Header>
        <h5 style={{ color: '#705921' }}>{tmima.descr} ({tmima.city})</h5>
      </Card.Header>
      <Card.Body>
        <Table striped hover>
          <thead>
            <tr>
              <th style={{ width: 50 }}>Θέσεις</th>
              <th>Επιστημονικό Πεδίο</th>
              <th>Μαθήματα</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              positions.map(position => {
                if (position.tmima.id == tmima.id) {
                  return (
                    <tr key={'pedio' + position.id}>
                      <td>{position.theseis}</td>
                      <td>{position.pedio}</td>
                      <td>{position.descr}</td>
                      <td style={{ textAlign: 'right', minWidth: '150px' }}>
                        <Button
                          variant="success"
                          size='sm'
                          onClick={() => handleCreateApplication(position)}
                        >
                          Υποβολή Αίτησης
                        </Button>
                      </td>
                    </tr>
                  )
                }
                else {
                  return null
                }
              })
            }
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

