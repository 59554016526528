import React from 'react'
import ReactDOM from 'react-dom/client'
import { useFormik } from 'formik'
import { Button, Form, Card, Alert } from 'react-bootstrap'
import Footer from '../layout/Footer'
import { changePasswordApi } from '../api/auth'

const styles = {
    wrapper: {
        marginTop: '30px',
        marginBottom: '30px',
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}

// ---------------------------------------------------------------
// Initial form values
// ---------------------------------------------------------------
const initialValues = {
    old_password: '',
    new_password1: '',
    new_password2: ''
}

// ---------------------------------------------------------------
// Form validation
// ---------------------------------------------------------------
const requiredFields = [
    'old_password', 'new_password1', 'new_password2'
]
const validateForm = values => {
    const errors = {}

    // Check for required fields
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Υποχρεωτικό πεδίο'
        }
    })

    // new_password1 and new_password2 should match
    if (!errors.new_password1 && !errors.new_password2 && values.new_password1 !== values.new_password2) {
        errors.new_password1 = "Οι κωδικοί δεν ταιριάζουν"
        errors.new_password2 = "Οι κωδικοί δεν ταιριάζουν"
    }

    return errors
}


// ****************************************************************************
export default function ChangePassword() {

    // ---------------------------------------------------------------
    // Local state
    // ---------------------------------------------------------------
    const [error, setError] = React.useState();

    // ---------------------------------------------------------------
    // Handle submit
    // ---------------------------------------------------------------
    const handleSubmit = (values) => {

        setError(null)
        changePasswordApi(values)
            .then(_ => {
                window.location.href = '/logout'
            })
            .catch(error => {
                setError(error)
            })
    }

    // ---------------------------------------------------------------
    // Formik hook
    // ---------------------------------------------------------------
    const formik = useFormik({
        initialValues,
        onSubmit: handleSubmit,
        validate: validateForm,
    })

    // ---------------------------------------------------------------
    // JSX
    // ---------------------------------------------------------------
    return (
        <div>
            <div style={styles.wrapper}>
                <div style={styles.main}>
                    <img src="/img/logo.png" width={400} />
                    <h2 className="mt-4">Ηλεκτρονική Υποβολή Αιτήσεων</h2>
                    <Card style={{ width: 500, marginTop: 30 }}>
                        <Card.Header>
                            <h5>Αλλαγή Κωδικού Πρόσβασης</h5>
                        </Card.Header>
                        <Card.Body>
                            {error ? <Alert variant='danger'>{error}</Alert> : null}
                            <Form noValidate onSubmit={formik.handleSubmit}>

                                {/* old_password */}
                                <Form.Group className="mb-3" controlId="old_password">
                                    <Form.Label>Παλιός Κωδικός (*)</Form.Label>
                                    <Form.Control
                                        name="old_password"
                                        type="password"
                                        value={formik.values.old_password || ''}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.touched.old_password && formik.errors.old_password}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.old_password}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                {/* new_password1 */}
                                <Form.Group className="mb-3" controlId="new_password1">
                                    <Form.Label>Νέος Κωδικός (*)</Form.Label>
                                    <Form.Control
                                        name="new_password1"
                                        type="password"
                                        value={formik.values.new_password1 || ''}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.touched.new_password1 && formik.errors.new_password1}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.new_password1}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                {/* new_password2 */}
                                <Form.Group className="mb-3" controlId="new_password2">
                                    <Form.Label>Επανάληψη Νέου Κωδικού (*)</Form.Label>
                                    <Form.Control
                                        name="new_password2"
                                        type="password"
                                        value={formik.values.new_password2 || ''}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.touched.new_password2 && formik.errors.new_password2}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.new_password2}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                {/* Submit Button */}
                                <div className="d-grid gap-2 mt-4">
                                    <Button variant="primary" type="submit">Αλλαγή Κωδικού</Button>
                                </div>

                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <Footer />
        </div>
    )
}

// ---------------------------------------------------------------
// // Mount into dom
// ---------------------------------------------------------------
if (document.getElementById('change-password')) {
    const root = ReactDOM.createRoot(document.getElementById('change-password'))
    root.render(<ChangePassword />)
}
