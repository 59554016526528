import axios from "axios"

// Fetch all
export function fetchTmimataApi() {
  return new Promise((resolve, reject) => {
    axios.get(`/api/tmimata`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject("Σφάλμα κατά την ανάκτηση των τμημάτων")
      })
  })
}

// Create new tmima
export function createTmimaApi(tmima) {
  return new Promise((resolve, reject) => {
    axios.post("/api/tmimata", tmima)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        if (error.response.data.message) reject(error.response.data.message)
        else reject("Σφάλμα κατά την δημιουργία του τμήματος")

      })
  })
}

// update tmima
export function updateTmimaApi(tmima) {
  return new Promise((resolve, reject) => {
    axios.put(`/api/tmimata/${tmima.id}`, tmima)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        if (error.response.data.message) reject(error.response.data.message)
        else reject("Σφάλμα κατά την ενημέρωση των στοιχείων του τμήματος")

      })
  })
}

// delete tmima 
export function deleteTmimaApi(tmima) {
  return new Promise((resolve, reject) => {
    axios.delete(`/api/tmimata/${tmima.id}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        if (error.response.data.message) reject(error.response.data.message)
        else reject("Σφάλμα κατά την διαγραφή του τμήματος")
      })
  })
}