import React from 'react'
import { formatDateTime } from '../../../../utils'


const styles = {
  hr: {
    height: 1,
    color: 'black',
    backgroundColor: 'black'
  },
  subtitle: {
    display: 'flex',
    gap: 20,
    flexWrap: 'wrap'
  }
}

export default function Header({ user }) {
  return (
    <>
      <h4>{user?.fullname}</h4>
      <hr style={styles.hr} />
      <div style={styles.subtitle}>
        <p>Δημιουργήθηκε: <b>{formatDateTime(user?.created_at)}</b></p>
        <p>Τελευταία Ενημέρωση: <b>{formatDateTime(user?.updated_at)}</b></p>
      </div>
    </>
  )
}
