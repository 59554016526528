import React from 'react'
import { Form } from 'react-bootstrap'
import { ApplicationEditContext } from '../../../pages/applications/edit'

export default function PositionData() {

    // ---------------------------------------------------------------
    // local state and context
    // ---------------------------------------------------------------
    const [application, setApplication] = React.useContext(ApplicationEditContext)

    // ---------------------------------------------------------------
    // JSX
    // ---------------------------------------------------------------
    return (
        <div>
            {/* sxoli */}
            <Form.Group className="mb-3" controlId="sxoli">
                <Form.Label>ΣΧΟΛΗ (*)</Form.Label>
                <Form.Control
                    name="sxoli"
                    type="text"
                    value={application?.position.tmima.sxoli.descr || ''}
                    disabled
                />
            </Form.Group>
            {/* tmima */}
            <Form.Group className="mb-3" controlId="tmima">
                <Form.Label>ΤΜΗΜΑ (*)</Form.Label>
                <Form.Control
                    name="tmima"
                    type="text"
                    value={application?.position.tmima.descr || ''}
                    disabled
                />
            </Form.Group>
            {/* pedio */}
            <Form.Group className="mb-3" controlId="pedio">
                <Form.Label>ΕΠΙΣΤΗΜΟΝΙΚΟ ΠΕΔΙΟ (*)</Form.Label>
                <Form.Control
                    name="pedio"
                    type="text"
                    value={application?.position.pedio || ''}
                    disabled
                />
            </Form.Group>
        </div>
    )
}
