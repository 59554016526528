import React from 'react'
import { useFormik } from 'formik'
import { Form, Modal, Button } from 'react-bootstrap'
import { checkUserByEmailApi } from '../../../../api/users'

// Υποχρεωτικά πεδια
const requiredFields = [
    'email',
    'surname',
    'firstname',
    'role_id'
]

function MemberModalForm({
    show,
    member,
    roles = [],
    sxoles = [],
    tmimata = [],
    onSubmit,
    onCancel
}) {

    // ---------------------------------------------------------------
    // Local state and context
    // ---------------------------------------------------------------
    const [filteredTmimata, setFilteredTmimata] = React.useState()
    const [showUserData, setShowUserData] = React.useState(false)
    const [newUser, setNewUser] = React.useState(false)
    const [edit, setEdit] = React.useState(false)

    // email and surname fields references to set focus
    const emailRef = React.useRef(null)

    // ---------------------------------------------------------------
    // Reset form on hide
    // ---------------------------------------------------------------
    React.useEffect(() => {
        formik.resetForm() // reset on close
        setShowUserData(false)
        setNewUser(false)
        formik.validateForm()
        if (show) emailRef.current.focus()
    }, [show])

    // ---------------------------------------------------------------
    // Filer tmimata on member change
    // ---------------------------------------------------------------
    React.useEffect(() => {
        handleSxoliChanged(member?.tmima?.sxoli.id)
        if (member?.id > 0) setEdit(true)
        else setEdit(false)
    }, [member])

    // ---------------------------------------------------------------
    // Handle sxoli selection changed - filter tmimata
    // ---------------------------------------------------------------
    const handleSxoliChanged = (value) => {
        formik.setFieldValue('sxoli_id', value)
        formik.setFieldValue('tmima_id', '')
        setFilteredTmimata(
            tmimata.filter(tmima => tmima.sxoli.id == value)
        )
    }

    // ---------------------------------------------------------------
    // handle role_id change
    // ---------------------------------------------------------------
    const handleRoleChange = (e) => {
        formik.setFieldValue('role_id', e.target.value)
        if (e.target.value == '100') {
            formik.setFieldValue('sxoli_id', '')
            formik.setFieldValue('tmima_id', '')
        }
    }

    // ---------------------------------------------------------------
    // Form validation
    // ---------------------------------------------------------------
    const validateForm = values => {

        const errors = {}

        // Check for required fields
        requiredFields.forEach(field => {
            if (!values[field]) {
                errors[field] = 'Υποχρεωτικό πεδίο'
            }
        })

        // Αν ο ρόλος έχει id 100 έχει δικαιώματα σε όλη την πρόσκλησ.
        // Διαφορετικά, το τμήμα και η σχολή απαιτούνται
        if (values.role_id && values.role_id != 100) {
            if (!values.sxoli_id) {
                errors.sxoli_id = 'Υποχρεωτικό πεδίο'
            }

            if (!values.tmima_id) {
                errors.tmima_id = 'Υποχρεωτικό πεδίο'
            }
        }

        // email format
        if (!errors.email && !String(values.email).toLocaleLowerCase().match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
            errors.email = "Μη έγκυρη μορφή email"
        }


        return errors
    }

    // ---------------------------------------------------------------
    // Initial form values
    // ---------------------------------------------------------------
    const initialValues = {
        id: member?.id || -1,
        email: member?.user.email || '',
        surname: member?.user.surname || '',
        firstname: member?.user.firstname || '',
        sxoli_id: member?.tmima?.sxoli?.id || '',
        tmima_id: member?.tmima?.id || '',
        role_id: member?.role.id || ''
    }

    // ---------------------------------------------------------------
    // Formik hook
    // ---------------------------------------------------------------
    const formik = useFormik({
        initialValues,
        onSubmit: onSubmit,
        validate: validateForm,
        enableReinitialize: true,
    })

    // ---------------------------------------------------------------
    // handle email change
    // ---------------------------------------------------------------
    const handleEmailChange = (value) => {
        formik.setFieldValue('email', value)
        if (!value || formik.errors.email) {
            setNewUser(false)
            setShowUserData(false)
        }
    }

    // ---------------------------------------------------------------
    // Handle email blur - check for user existance by email
    // ---------------------------------------------------------------
    const handleEmailBlur = (e) => {
        if (formik.errors.email || !e.target.value || edit) return
        checkUserByEmailApi(formik.values.email)
            .then(response => {
                if (response) {

                    formik.setValues({
                        id: formik.values.id,
                        email: formik.values.email,
                        surname: response.surname,
                        firstname: response.firstname,
                        sxoli_id: '',
                        tmima_id: '',
                        role_id: ''
                    })
                    setNewUser(false)
                    setShowUserData(true)
                }
                else {
                    formik.setValues({
                        id: formik.values.id,
                        email: formik.values.email,
                        surname: '',
                        firstname: '',
                        sxoli_id: '',
                        tmima_id: '',
                        role_id: ''
                    })
                    setNewUser(true)
                    setShowUserData(true)
                }
            })
            .catch(error => console.log(error))
    }


    // ---------------------------------------------------------------
    // JSX
    // ---------------------------------------------------------------
    return (
        <Modal show={show} backdrop="static" keyboard={false} size="lg">
            <Modal.Header>
                <Modal.Title>{member ? "Επεξεργασία Μέλους" : "Νέo Μέλος"}</Modal.Title>
            </Modal.Header >
            <Modal.Body>
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <p style={{ fontSize: 14 }}>
                        Πληκτρολογείστε το email του χρήστη. Αν δεν υπάρχει θα δημιουργηθεί νέος λογαριασμός.
                    </p>
                    {/* email */}
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label>E-MAIL (*)</Form.Label>
                        <Form.Control
                            size='sm'
                            name="email"
                            type="text"
                            value={formik.values.email || ''}
                            onChange={(e) => handleEmailChange(e.target.value)}
                            isInvalid={formik.touched.email && formik.errors.email}
                            onBlur={handleEmailBlur}
                            ref={emailRef}
                            readOnly={edit}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {/* New user message */}
                    {
                        newUser && !edit &&
                        <p style={{ color: '#198754', fontSize: 14 }}>Δεν υπάρχει χρήστης με αυτό το email. Καταχωρείστε το ονοματεπώνυμό του για την δημιουργία νέου. <br />Ο χρήσης θα ειδοποιηθεί με email  </p>
                    }

                    {
                        (showUserData || edit) &&
                        <>

                            {/* surname */}
                            <Form.Group className="mb-3" controlId="surname">
                                <Form.Label>ΕΠΩΝΥΜΟ (*)</Form.Label>
                                <Form.Control
                                    size='sm'
                                    name="surname"
                                    type="text"
                                    value={formik.values.surname || ''}
                                    onChange={formik.handleChange}
                                    isInvalid={formik.touched.surname && formik.errors.surname}
                                    readOnly={!newUser || edit}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.surname}
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* firstname */}
                            <Form.Group className="mb-3" controlId="firstname">
                                <Form.Label>ΟΝΟΜΑ (*)</Form.Label>
                                <Form.Control
                                    size='sm'
                                    name="firstname"
                                    type="text"
                                    value={formik.values.firstname || ''}
                                    onChange={formik.handleChange}
                                    isInvalid={formik.touched.firstname && formik.errors.firstname}
                                    readOnly={!newUser || edit}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.firstname}
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* role_id */}
                            <Form.Group className="mb-3" controlId="role_id">
                                <Form.Label>ΡΟΛΟΣ (*)</Form.Label>
                                <Form.Control
                                    size='sm'
                                    as="select"
                                    value={formik.values.role_id || ''}
                                    onChange={handleRoleChange}
                                    onBlur={() => formik.setFieldTouched('role_id')}
                                    isInvalid={formik.touched.role_id && formik.errors.role_id}
                                >
                                    <option value="">Επιλέξτε τον ρόλο του χρήστη...</option>
                                    {
                                        roles?.map(role => <option key={role.id} value={role.id}>{role.descr}</option>)
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.role_id}
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* Μήνυμα επιλογής τμήματος - μόνο για τους ρόλους που απαιτείται τμήμα*/}
                            {
                                (Boolean(formik.values.role_id) && formik.values.role_id != '100') &&
                                <p style={{ color: 'blue', fontSize: 14 }}>
                                    Επιλέξτε το τμήμα αρμοδιότητας. Αν επιθυμείτε πρόσβαση στις αιτήσεις περισσοτέρων του ενός τμημάτων θα πρέπει να γίνει νέα καταχώρηση για το καθένα.<br />
                                </p>
                            }

                            {/* sxoli_id */}
                            {
                                (Boolean(formik.values.role_id) && formik.values.role_id != '100') &&
                                <Form.Group className="mb-3" controlId="sxoli_id">
                                    <Form.Label>ΣΧΟΛΗ</Form.Label>
                                    <Form.Control
                                        size='sm'
                                        as="select"
                                        value={formik.values.sxoli_id || ''}
                                        onChange={e => handleSxoliChanged(e.target.value)}
                                        onBlur={() => formik.setFieldTouched('sxoli_id')}
                                        isInvalid={formik.touched.sxoli_id && formik.errors.sxoli_id}
                                    >
                                        <option value="">Επιλέξτε τη σχολή ...</option>
                                        {
                                            sxoles?.map(sxoli => <option key={sxoli.id} value={sxoli.id}>{sxoli.descr}</option>)
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.sxoli_id}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            }

                            {/* tmima_id */}
                            {
                                (Boolean(formik.values.role_id) && formik.values.role_id != '100') &&
                                <Form.Group className="mb-3" controlId="tmima_id">
                                    <Form.Label>ΤΜΗΜΑ</Form.Label>
                                    <Form.Control
                                        size='sm'
                                        as="select"
                                        value={formik.values.tmima_id || ''}
                                        onChange={e => formik.setFieldValue('tmima_id', e.target.value)}
                                        onBlur={() => formik.setFieldTouched('tmima_id')}
                                        isInvalid={formik.touched.tmima_id && formik.errors.tmima_id}
                                    >
                                        <option value="">Επιλέξτε το τμήμα ...</option>
                                        {
                                            filteredTmimata?.map(tmima => <option key={tmima.id} value={tmima.id}>{tmima.descr}</option>)
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.tmima_id}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            }
                        </>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={formik.handleSubmit}>Καταχώρηση</Button>
                <Button variant="danger" onClick={onCancel}>Ακύρωση</Button>
            </Modal.Footer>
        </Modal >
    )
}

export default MemberModalForm



