import React from 'react'
import { useFormik } from 'formik'
import { Button, Form } from 'react-bootstrap'
import { FormTabsContext } from './FormTabs'
import { updateContactDataApi } from '../../../api/applications'
import { ApplicationEditContext } from '../../../pages/applications/edit'
import useLoader from '../../../hooks/useLoader'

/* props
- application
*/
export default function ContactTab() {

  // ---------------------------------------------------------------
  // local state and context
  // ---------------------------------------------------------------
  const [application, setApplication] = React.useContext(ApplicationEditContext)
  const { setActiveTab } = React.useContext(FormTabsContext)
  const { startLoader, stopLoader } = useLoader()

  // ---------------------------------------------------------------
  // Handle submit
  // ---------------------------------------------------------------
  const handleSubmit = (values) => {
    startLoader()
    updateContactDataApi(values)
      .then(response => {
        // setApplication(response)
        stopLoader()
        setActiveTab(2)
      })
      .catch(error => {
        console.log(error)
        stopLoader()
      })
  }

  // ---------------------------------------------------------------
  // Form validation
  // ---------------------------------------------------------------
  const requiredFields = [
    'address', 'city', 'tk'
  ]

  const validateForm = values => {
    const errors = {}

    // validate required fields
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = 'Υποχρεωτικό πεδίο'
      }
    })

    // Τουλάχιστον ένα τηλέφωνο επικοινωνίας
    if (!values.phone_home && !values.phone_work && !values.mobil) {
      errors.phone_home = "Δώστε τουλάχιστον ένα τηλέφωνο επικοινωνίας"
      errors.phone_work = "Δώστε τουλάχιστον ένα τηλέφωνο επικοινωνίας"
      errors.mobil = "Δώστε τουλάχιστον ένα τηλέφωνο επικοινωνίας"
    }

    // Μήκος πεδίων
    if (values.address && values.address.length > 255) errors.address = "Πληκτρολογείστε μέχρι 255 χαρακτήρες"
    if (values.city && values.city.length > 255) errors.city = "Πληκτρολογείστε μέχρι 255 χαρακτήρες"
    if (values.tk && values.tk.length > 10) errors.tk = "Πληκτρολογείστε μέχρι 10 χαρακτήρες"
    if (values.phone_home && values.phone_home.length > 10) errors.phone_home = "Πληκτρολογείστε μέχρι 10 χαρακτήρες"
    if (values.phone_work && values.phone_work.length > 10) errors.phone_work = "Πληκτρολογείστε μέχρι 10 χαρακτήρες"
    if (values.mobil && values.mobil.length > 10) errors.mobil = "Πληκτρολογείστε μέχρι 10 χαρακτήρες"
    if (values.agent_name && values.agent_name.length > 255) errors.agent_name = "Πληκτρολογείστε μέχρι 255 χαρακτήρες"
    if (values.agent_phone && values.agent_phone.length > 10) errors.agent_phone = "Πληκτρολογείστε μέχρι 10 χαρακτήρες"


    return errors;
  }

  // ---------------------------------------------------------------
  // Initial form values
  // ---------------------------------------------------------------
  const initialValues = {
    application_id: application?.id,
    address: application?.contact.address,
    city: application?.contact.city,
    tk: application?.contact.tk,
    phone_home: application?.contact.phone_home,
    phone_work: application?.contact.phone_work,
    mobil: application?.contact.mobil,
    agent_name: application?.contact.agent_name,
    agent_phone: application?.contact.agent_phone
  }

  // ---------------------------------------------------------------
  // Formik hook
  // ---------------------------------------------------------------
  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validate: validateForm,
    enableReinitialize: true,
  })

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <div className="mt-3">
      <Form noValidate>

        {/* address */}
        <Form.Group className="mb-3" controlId="address">
          <Form.Label>ΟΔΟΣ ΚΑΙ ΑΡΙΘΜΟΣ (*)</Form.Label>
          <Form.Control
            name="address"
            type="text"
            value={formik.values.address || ''}
            onChange={formik.handleChange}
            isInvalid={formik.touched.address && formik.errors.address}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.address}
          </Form.Control.Feedback>
        </Form.Group>

        {/* city */}
        <Form.Group className="mb-3" controlId="city">
          <Form.Label>ΠΟΛΗ/ΠΕΡΙΦΕΡΕΙΑ (*)</Form.Label>
          <Form.Control
            name="city"
            type="text"
            value={formik.values.city || ''}
            onChange={formik.handleChange}
            isInvalid={formik.touched.city && formik.errors.city}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.city}
          </Form.Control.Feedback>
        </Form.Group>

        {/* tk */}
        <Form.Group className="mb-3" controlId="tk">
          <Form.Label>Τ.Κ. (*)</Form.Label>
          <Form.Control
            name="tk"
            type="text"
            value={formik.values.tk || ''}
            onChange={formik.handleChange}
            isInvalid={formik.touched.tk && formik.errors.tk}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.tk}
          </Form.Control.Feedback>
        </Form.Group>

        {/* phone_home */}
        <Form.Group className="mb-3" controlId="phone_home">
          <Form.Label>ΑΡΙΘΜΟΣ ΤΗΛΕΦΩΝΟΥ ΟΙΚΙΑΣ</Form.Label>
          <Form.Control
            name="phone_home"
            type="text"
            value={formik.values.phone_home || ''}
            onChange={formik.handleChange}
            isInvalid={formik.touched.phone_home && formik.errors.phone_home}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.phone_home}
          </Form.Control.Feedback>
        </Form.Group>

        {/* phone_work */}
        <Form.Group className="mb-3" controlId="phone_work">
          <Form.Label>ΑΡΙΘΜΟΣ ΤΗΛΕΦΩΝΟΥ ΕΡΓΑΣΙΑΣ</Form.Label>
          <Form.Control
            name="phone_work"
            type="text"
            value={formik.values.phone_work || ''}
            onChange={formik.handleChange}
            isInvalid={formik.touched.phone_work && formik.errors.phone_work}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.phone_work}
          </Form.Control.Feedback>
        </Form.Group>

        {/* mobil */}
        <Form.Group className="mb-3" controlId="mobil">
          <Form.Label>ΑΡΙΘΜΟΣ ΚΙΝΗΤΟΥ ΤΗΛΕΦΩΝΟΥ</Form.Label>
          <Form.Control
            name="mobil"
            type="text"
            value={formik.values.mobil || ''}
            onChange={formik.handleChange}
            isInvalid={formik.touched.mobil && formik.errors.mobil}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.mobil}
          </Form.Control.Feedback>
        </Form.Group>

        {/* agent_name */}
        <Form.Group className="mb-3" controlId="agent_name">
          <Form.Label>ΟΝΟΜΑΤΕΠΩΝΥΜΟ ΠΡΟΣΩΠΟΥ ΠΡΟΣ ΕΙΔΟΠΟΙΗΣΗ ΣΕ ΠΕΡΙΠΤΩΣΗ ΑΠΟΥΣΙΑΣ</Form.Label>
          <Form.Control
            name="agent_name"
            type="text"
            value={formik.values.agent_name || ''}
            onChange={formik.handleChange}
            isInvalid={formik.touched.agent_name && formik.errors.agent_name}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.agent_name}
          </Form.Control.Feedback>
        </Form.Group>

        {/* agent_phone */}
        <Form.Group className="mb-3" controlId="agent_phone">
          <Form.Label>ΑΡΙΘΜΟΣ ΤΗΛΕΦΩΝΟΥ ΠΡΟΣΩΠΟΥ ΠΡΟΣ ΕΙΔΟΠΟΙΗΣΗ ΣΕ ΠΕΡΙΠΤΩΣΗ ΑΠΟΥΣΙΑΣ</Form.Label>
          <Form.Control
            name="agent_phone"
            type="text"
            value={formik.values.agent_phone || ''}
            onChange={formik.handleChange}
            isInvalid={formik.touched.agent_phone && formik.errors.agent_phone}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.agent_phone}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Κουμπιά προηγούμενης και επόμενης σελίδας */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 30 }}>
          <Button variant="primary" onClick={() => setActiveTab(0)}>Προηγούμενη Σελίδα</Button>
          <Button type="submit" variant="primary" onClick={formik.handleSubmit}>Αποθήκευση και Επόμενη Σελίδα</Button>
        </div>

      </Form >


    </div >
  )
}
