import React from 'react'
import DataTable from 'react-data-table-component'
import { formatDate, formatDateTime } from '../../../../utils'

// Datatabble columns
const columns = [
  {
    name: 'Ημερομηνία',
    selector: row => formatDate(row.invitation.prot_date),
    sortable: true,
    wrap: true,
    maxWidth: '150px',
  },
  {
    name: 'Αρ. πρωτ.',
    selector: row => row.invitation?.prot_aa,
    sortable: true,
    wrap: true,
    maxWidth: '150px',
  },
  {
    name: 'Περιγραφή',
    selector: row => row.invitation?.descr,
    sortable: true,
    wrap: true,
  },
  {
    name: 'Ρόλος',
    selector: row => row.role?.descr,
    sortable: true,
    wrap: true,
    maxWidth: '200px',
  },
  {
    name: 'Λήξη',
    selector: row => formatDateTime(row.invitation.deadline),
    sortable: true,
    wrap: true,
    maxWidth: '200px',
  },

]

// Row styles
const conditionalRowStyles = [
  {
    when: row => row.invitation.isopen,
    style: {
      color: 'green'
    }
  },
  {
    when: row => !row.invitation.isopen,
    style: {
      color: 'red'
    }
  }
]

export default function InvitationsAsMember({ user }) {
  return (
    <div className="card mt-4">
      <div className="card-header" style={{ display: "flex", justifyContent: 'space-between' }}>
        <h5 className="card-title">Εποπτευόμενες Προσκλήσεις</h5>
      </div>
      <div className="card-body">
        <DataTable
          columns={columns}
          data={user.members}
          defaultSortFieldId={5}
          defaultSortAsc={false}
          pagination
          paginationComponentOptions={{
            rowsPerPageText: 'Εγγραφές ανά σελίδα',
            rangeSeparatorText: 'από',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Όλα',
          }}
          theme='default'
          noDataComponent="Δεν υπάρχουν δεδομένα"
          highlightOnHover
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    </div>
  )
}
