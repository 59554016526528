import React from 'react'

export default function Footer() {
  return (
    <footer id="colophon" className="site-footer " role="contentinfo">
      <div className="ak-container">
        <div className="footer_area clearfix">
          <div className="footer_area_one">
            <section id="media_image-7" className="widget widget_media_image"><a href="http://rc.ihu.gr"><img width="531" height="92" src="https://rc.ihu.gr/wp-content/uploads/2020/09/logo-footer.png" className="image wp-image-2430  attachment-full size-full" alt="" loading="lazy" style={{ maxWidth: '100%', height: 'auto' }} srcSet="https://rc.ihu.gr/wp-content/uploads/2020/09/logo-footer.png 531w, https://rc.ihu.gr/wp-content/uploads/2020/09/logo-footer-300x52.png 300w, https://rc.ihu.gr/wp-content/uploads/2020/09/logo-footer-285x49.png 285w" sizes="(max-width: 531px) 100vw, 531px" /></a></section>
          </div>
          <div className="footer_area_two">
            <section id="enlighten_info-3" className="widget widget_enlighten_info">
              <div className="footer_info_wrap">
                <div className="footer_widget_title">
                  ΕΙΔΙΚΟΣ ΛΟΓΑΡΙΑΣΜΟΣ ΚΟΝΔΥΛΙΩΝ &amp; ΕΡΕΥΝΑΣ ΔΙΠΑΕ <div className="faq_dot"></div>
                </div>

                <div className="info_wrap">
                  <div className="location_info">
                    <span className="fa_icon_info"><i className="fa fa-map-marker" aria-hidden="true"></i></span>
                    <span className="location">14ο χλμ Ε.Ο. Θεσσαλονίκης - Μουδανίων, 57001</span>
                  </div>
                  <div className="phone_info">
                    <span className="fa_icon_info"><i className="fa fa-phone" aria-hidden="true"></i></span>
                    <span className="phone">2310474564</span>
                  </div>
                  <div className="fax_info">
                    <span className="fa_icon_info"><i className="fa fa-fax" aria-hidden="true"></i></span>
                    <span className="fax">2310474569</span>
                  </div>
                  <div className="email_info">
                    <span className="fa_icon_info"><i className="fa fa-envelope-o" aria-hidden="true"></i></span>
                    <span className="email">rc@ihu.edu.gr</span>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="footer_area_three">
            <section id="media_image-12" className="widget widget_media_image"><a href="http://www.espa.gr"><img width="210" height="106" src="https://rc.ihu.gr/wp-content/uploads/2019/02/e-bannerespaEKT210x100.jpg" className="image wp-image-359  attachment-full size-full" alt="" loading="lazy" style={{ maxWidth: '100%', height: 'auto' }} /></a></section>
            <section id="media_image-17" className="widget widget_media_image"><a href="https://www.smart-sea.eu"><img width="101" height="85" src="https://rc.ihu.gr/wp-content/uploads/2020/09/smart-sea.png" className="image wp-image-2108  attachment-full size-full" alt="" loading="lazy" style={{ maxWidth: '100%', height: 'auto' }} srcSet="https://rc.ihu.gr/wp-content/uploads/2020/09/smart-sea.png 101w, https://rc.ihu.gr/wp-content/uploads/2020/09/smart-sea-100x85.png 100w" sizes="(max-width: 101px) 100vw, 101px" /></a></section>
            <section id="media_image-18" className="widget widget_media_image"><a href="https://www.edrivetour.eu"><img width="120" height="70" src="https://rc.ihu.gr/wp-content/uploads/2020/09/e-drive-250p-t-1.png" className="image wp-image-2110  attachment-full size-full" alt="" loading="lazy" style={{ maxWidth: '100%', height: 'auto' }} /></a></section>
          </div>
        </div>

        <div className="site-info">
          © 2022 I.H.U. Research Committee - Special Account for Research Funds </div>

      </div>
    </footer>
  )
}


