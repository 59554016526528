import React from 'react'
import { GrCheckbox, GrCheckboxSelected } from 'react-icons/gr'

export default function Roles({ user }) {

  return (
    <div style={{ display: 'flex', gap: 20 }}>

      <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
        {user?.is_admin ? <GrCheckboxSelected /> : <GrCheckbox />}
        <div>Διαχειριστής</div>
      </div>

      <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
        {user?.is_editor ? <GrCheckboxSelected /> : <GrCheckbox />}
        <div>Συντάκτης</div>
      </div>

      <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
        {user?.is_supervisor ? <GrCheckboxSelected /> : <GrCheckbox />}
        <div>Επόπτης</div>
      </div>

    </div>
  )
}
