import React from 'react'
import { useFormik } from 'formik'
import { Button, Form } from 'react-bootstrap'
import { updatePersonalDataApi } from '../../../api/applications'
import { FormTabsContext } from './FormTabs'
import DatePicker, { registerLocale } from "react-datepicker";
import { ApplicationEditContext } from '../../../pages/applications/edit'
import "react-datepicker/dist/react-datepicker.css";
import el from 'date-fns/locale/el';
import { formatFnsDbDate, parseDate } from '../../../utils'
import useLoader from '../../../hooks/useLoader'
registerLocale('el', el)


export default function PersonalTab() {

  // ---------------------------------------------------------------
  // local state and context
  // ---------------------------------------------------------------
  const [application, setApplication] = React.useContext(ApplicationEditContext)
  const { setActiveTab } = React.useContext(FormTabsContext)
  const { startLoader, stopLoader } = useLoader()

  // ---------------------------------------------------------------
  // Handle submit
  // ---------------------------------------------------------------
  const handleSubmit = (values) => {
    startLoader()
    updatePersonalDataApi(values)
      .then(response => {
        setApplication(response)
        stopLoader()
        setActiveTab(1)
      })
      .catch(error => {
        console.log(error)
        stopLoader()
      })
  }

  // ---------------------------------------------------------------
  // Form validation
  // ---------------------------------------------------------------
  const requiredFields = [
    'surname', 'firstname', 'fathername', 'birthdate', 'nationality', 'job', 'afm', 'adt'
  ]

  const validateForm = values => {
    const errors = {}

    // validate required fields
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = 'Υποχρεωτικό πεδίο'
      }
    })

    // Μήκος πεδίων
    if (values.surname && values.surname.length > 255) errors.surname = "Πληκτρολογείστε μέχρι 255 χαρακτήρες"
    if (values.firstname && values.firstname.length > 255) errors.surname = "Πληκτρολογείστε μέχρι 255 χαρακτήρες"
    if (values.fathername && values.fathername.length > 255) errors.surname = "Πληκτρολογείστε μέχρι 255 χαρακτήρες"
    if (values.job && values.job.length > 255) errors.surname = "Πληκτρολογείστε μέχρι 255 χαρακτήρες"
    if (values.afm && values.afm.length > 9) errors.afm = "Πληκτρολογείστε μέχρι 9 χαρακτήρες"
    if (values.adt && values.adt.length > 10) errors.adt = "Πληκτρολογείστε μέχρι 10 χαρακτήρες"
    if (values.nationality && values.nationality.length > 255) errors.surname = "Πληκτρολογείστε μέχρι 255 χαρακτήρες"

    return errors;
  }

  // ---------------------------------------------------------------
  // Initial form values
  // ---------------------------------------------------------------
  const initialValues = {
    application_id: application?.id,
    surname: application?.personal.surname,
    firstname: application?.personal.firstname,
    fathername: application?.personal.fathername,
    birthdate: application?.personal.birthdate,
    nationality: application?.personal.nationality,
    job: application?.personal.job,
    afm: application?.personal.afm,
    adt: application?.personal.adt
  }

  // ---------------------------------------------------------------
  // Formik hook
  // ---------------------------------------------------------------
  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validate: validateForm,
    enableReinitialize: true,
  })

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <div className="mt-3">
      <Form noValidate onSubmit={formik.handleSubmit}>

        {/* surname */}
        <Form.Group className="mb-3" controlId="surname">
          <Form.Label>ΕΠΩΝΥΜΟ (*)</Form.Label>
          <Form.Control
            name="surname"
            type="text"
            value={formik.values.surname || ''}
            onChange={formik.handleChange}
            isInvalid={formik.touched.surname && formik.errors.surname}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.surname}
          </Form.Control.Feedback>
        </Form.Group>

        {/* firstname */}
        <Form.Group className="mb-3" controlId="firstname">
          <Form.Label>ΟΝΟΜΑ (*)</Form.Label>
          <Form.Control
            name="firstname"
            type="text"
            value={formik.values.firstname || ''}
            onChange={formik.handleChange}
            isInvalid={formik.touched.firstname && formik.errors.firstname}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.firstname}
          </Form.Control.Feedback>
        </Form.Group>

        {/* fathername */}
        <Form.Group className="mb-3" controlId="fathername">
          <Form.Label>ΠΑΤΡΩΝΥΜΟ (*)</Form.Label>
          <Form.Control
            name="fathername"
            type="text"
            value={formik.values.fathername || ''}
            onChange={formik.handleChange}
            isInvalid={formik.touched.fathername && formik.errors.fathername}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.fathername}
          </Form.Control.Feedback>
        </Form.Group>

        {/* job */}
        <Form.Group className="mb-3" controlId="job">
          <Form.Label>ΕΠΑΓΓΕΛΜΑ/ΙΔΙΟΤΗΤΑ (*)</Form.Label>
          <Form.Control
            name="job"
            type="text"
            value={formik.values.job || ''}
            onChange={formik.handleChange}
            isInvalid={formik.touched.job && formik.errors.job}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.job}
          </Form.Control.Feedback>
        </Form.Group>

        {/* afm */}
        <Form.Group className="mb-3" controlId="afm">
          <Form.Label>Α.Φ.Μ. (*)</Form.Label>
          <Form.Control
            name="afm"
            type="text"
            value={formik.values.afm || ''}
            onChange={formik.handleChange}
            isInvalid={formik.touched.afm && formik.errors.afm}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.afm}
          </Form.Control.Feedback>
        </Form.Group>

        {/* adt */}
        <Form.Group className="mb-3" controlId="adt">
          <Form.Label>Α.Δ.Τ. (*)</Form.Label>
          <Form.Control
            name="adt"
            type="text"
            value={formik.values.adt || ''}
            onChange={formik.handleChange}
            isInvalid={formik.touched.afm && formik.errors.adt}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.adt}
          </Form.Control.Feedback>
        </Form.Group>

        {/* birthdate */}
        <div className="form-group mb-3">
          <label htmlFor="done_at" className="form-label">ΗΜΕΡΟΜΗΝΙΑ ΓΕΝΝΗΣΗΣ (*)</label>
          <DatePicker
            selected={formik.values.birthdate ? parseDate(formik.values.birthdate) : null}
            onChange={dt => formik.setFieldValue('birthdate', formatFnsDbDate(dt))}
            // onSelect={() => formik.setFieldValue('birthdate', formatFnsDbDate(dt))}
            locale='el'
            dateFormat='dd/MM/yyyy'
            className='form-control'
            maxDate={new Date()}
            placeholderText='Eπιλέξτε ή πληκτρολογείστε...'
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={80}
          />
          {formik.touched.birthdate
            ?
            <Form.Control.Feedback type="invalid" style={{ display: 'block', width: '100%', marginTop: '0.25rem' }}>
              {formik.errors.birthdate}
            </Form.Control.Feedback>
            : null
          }
        </div>

        {/* nationality */}
        <Form.Group className="mb-3" controlId="nationality">
          <Form.Label>ΥΠΗΚΟΟΤΗΤΑ (*)</Form.Label>
          <Form.Control
            name="nationality"
            type="text"
            value={formik.values.nationality || ''}
            onChange={formik.handleChange}
            isInvalid={formik.touched.nationality && formik.errors.nationality}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.nationality}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Submit button */}
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: 30 }}>
          <Button type='submit' variant="primary" className="pull-right">Αποθήκευση και Επόμενη Σελίδα</Button>
        </div>

      </Form>
    </div>
  )
}