// Styles
export const styles = {
    section: {
        marginBottom: '20px',
    },
    caption: {
        fontSize: '20px',
        color: '#429325',
    },
    dataGroup: {
        display: 'flex',
        gap: '10px'
    },
    label: {
        fontWeight: 700
    },
    data: {
        fontSize: '16px'
    }
}