import React from 'react'
import { useFormik } from 'formik'
import { Form, Modal, Button } from 'react-bootstrap'


export default function PositionModalForm({
  show,
  position,
  invitationType,
  sxoles = [],
  tmimata = [],
  onSubmit,
  onCancel
}) {

  // ---------------------------------------------------------------
  // Local state and context
  // ---------------------------------------------------------------
  const [filteredTmimata, setFilteredTmimata] = React.useState()

  // ---------------------------------------------------------------
  // Reset form on hide
  // ---------------------------------------------------------------
  React.useEffect(() => {
    if (!show) {
      formik.resetForm() // reset on close
    }
  }, [show])

  // ---------------------------------------------------------------
  // Filer tmimata on position change
  // ---------------------------------------------------------------
  React.useEffect(() => {
    handleSxoliChanged(position?.tmima.sxoli.id)
  }, [position])

  // ---------------------------------------------------------------
  // Handle sxoli selection changed - filter tmimata
  // ---------------------------------------------------------------
  const handleSxoliChanged = (sxoliId) => {
    formik.setFieldValue('tmima_id', '')
    setFilteredTmimata(
      tmimata.filter(tmima => tmima.sxoli.id == sxoliId)
    )
  }

  // ---------------------------------------------------------------
  // Form validation
  // ---------------------------------------------------------------
  const validateForm = values => {

    const errors = {}

    // Υποχρεωτικά πεδια τύπου 1 (διδακτική εμπειρία)
    var requiredFields = []
    var fieldLengths = []
    if (invitationType == 1) {
      requiredFields = ['aa', 'sxoli_id', 'tmima_id', 'pedio', 'theseis', 'descr']
      fieldLengths = [
        {
          name: 'pedio',
          length: 1000
        },
        {
          name: 'descr',
          length: 1000
        }
      ]
    }
    // TODO: Add requiried fields and field lengths for other invitation types

    // Check for required fields
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = 'Υποχρεωτικό πεδίο'
      }
    })

    // Check for field lengths
    fieldLengths.forEach(field => {
      if (values[field.name] && values[field.name].length > field.length) {
        errors[field.name] = `Πληκτρολογείστε μέχρι ${field.length} χαρακτήρες`
      }
    })

    return errors
  }

  // ---------------------------------------------------------------
  // Initial form values
  // ---------------------------------------------------------------
  const initialValues = {
    id: position?.id || -1,
    aa: position?.aa || '',
    sxoli_id: position?.tmima?.sxoli.id || '',
    tmima_id: position?.tmima?.id || '',
    pedio: position?.pedio || '',
    theseis: position?.theseis || '',
    descr: position?.descr || ''
  }

  // ---------------------------------------------------------------
  // Formik hook
  // ---------------------------------------------------------------
  const formik = useFormik({
    initialValues,
    onSubmit: onSubmit,
    validate: validateForm,
    enableReinitialize: true,
  })

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <Modal show={show} backdrop="static" keyboard={false} size="lg">
      <Modal.Header>
        <Modal.Title>{position ? "Επεξεργασία Θέσης" : "Νέα Θέση"}</Modal.Title>
      </Modal.Header >
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <AaField formik={formik} required={invitationType == 1} />
          <SxoliField formik={formik} sxoles={sxoles} required={invitationType == 1} onChange={handleSxoliChanged} />
          <TmimaField formik={formik} tmimata={filteredTmimata} required={invitationType == 1} />
          <PedioField formik={formik} required={invitationType == 1} />
          <TheseisField formik={formik} required={invitationType == 1} />
          <DescrField formik={formik} required={invitationType == 1} />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={formik.handleSubmit}>Καταχώρηση</Button>
        <Button variant="danger" onClick={onCancel}>Ακύρωση</Button>
      </Modal.Footer>
    </Modal>
  )
}


// **********************************************************************************************
function AaField({ formik, required }) {
  return (
    <Form.Group className="mb-3" controlId="aa">
      <Form.Label>Α/Α {required && '(*)'}</Form.Label>
      <Form.Control
        name="aa"
        type="number"
        value={formik.values.aa || ''}
        onChange={formik.handleChange}
        isInvalid={formik.touched.aa && formik.errors.aa}
      />
      <Form.Control.Feedback type="invalid">
        {formik.errors.aa}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

// **********************************************************************************************
function SxoliField({ formik, sxoles, required, onChange }) {

  const handleChanged = (e) => {
    formik.setFieldValue('sxoli_id', e.target.value)
    onChange(e.target.value)
  }

  return (
    <Form.Group className="mb-3" controlId="sxoli_id">
      <Form.Label>ΣΧΟΛΗ {required && '(*)'}</Form.Label>
      <Form.Control
        as="select"
        value={formik.values.sxoli_id || ''}
        onChange={handleChanged}
        onBlur={() => formik.setFieldTouched('sxoli_id')}
        isInvalid={formik.touched.sxoli_id && formik.errors.sxoli_id}
      >
        <option value="">Επιλέξτε ...</option>
        {
          sxoles?.map(sxoli => <option key={sxoli.id} value={sxoli.id}>{sxoli.descr}</option>)
        }
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {formik.errors.sxoli_id}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

// **********************************************************************************************
function TmimaField({ formik, tmimata, required }) {
  return (
    <Form.Group className="mb-3" controlId="tmima_id">
      <Form.Label>ΤΜΗΜΑ {required && '(*)'}</Form.Label>
      <Form.Control
        as="select"
        value={formik.values.tmima_id || ''}
        onChange={e => formik.setFieldValue('tmima_id', e.target.value)}
        onBlur={() => formik.setFieldTouched('tmima_id')}
        isInvalid={formik.touched.tmima_id && formik.errors.tmima_id}
      >
        <option value="">Επιλέξτε ...</option>
        {
          tmimata?.map(tmima => <option key={tmima.id} value={tmima.id}>{tmima.descr}</option>)
        }
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {formik.errors.tmima_id}
      </Form.Control.Feedback>
    </Form.Group>
  )
}


// **********************************************************************************************
function PedioField({ formik, required }) {
  return (
    <Form.Group className="mb-3" controlId="pedio">
      <Form.Label>ΕΠΙΣΤΗΜΟΝΙΚΟ ΠΕΔΙΟ {required && '(*)'}</Form.Label>
      <Form.Control
        name="pedio"
        type="text"
        value={formik.values.pedio || ''}
        onChange={formik.handleChange}
        isInvalid={formik.touched.pedio && formik.errors.pedio}
      />
      <Form.Control.Feedback type="invalid">
        {formik.errors.pedio}
      </Form.Control.Feedback>
    </Form.Group>
  )
}


// **********************************************************************************************
function TheseisField({ formik, required }) {
  return (
    <Form.Group className="mb-3" controlId="theseis">
      <Form.Label>ΟΦΕΛΟΥΜΕΝΟΙ {required && '(*)'}</Form.Label>
      <Form.Control
        name="theseis"
        type="number"
        value={formik.values.theseis || ''}
        onChange={formik.handleChange}
        isInvalid={formik.touched.theseis && formik.errors.theseis}
      />
      <Form.Control.Feedback type="invalid">
        {formik.errors.theseis}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

// **********************************************************************************************
function DescrField({ formik, required }) {
  return (
    <Form.Group className="mb-3" controlId="descr">
      <Form.Label>ΠΕΡΙΓΡΑΦΗ/ΜΑΘΗΜΑΤΑ {required && '(*)'}</Form.Label>
      <Form.Control
        name="descr"
        type="text"
        value={formik.values.descr || ''}
        onChange={formik.handleChange}
        isInvalid={formik.touched.descr && formik.errors.descr}
      />
      <Form.Control.Feedback type="invalid">
        {formik.errors.descr}
      </Form.Control.Feedback>
    </Form.Group>
  )
}