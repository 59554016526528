import React from 'react'
import ReactDOM from 'react-dom/client'
import { Container, Table } from 'react-bootstrap'
import { fetchUserApplicationsApi } from '../../api/applications'
import { formatDateTime } from '../../utils'
import useLoader from '../../hooks/useLoader'

export default function ApplicationsIndex() {

  // ---------------------------------------------------------------
  // Local state and context
  // ---------------------------------------------------------------
  const [applications, setApplications] = React.useState([])
  const { loader: pageLoader, startLoader: startPageLoader, stopLoader: stopPageLoader } = useLoader()

  // ---------------------------------------------------------------
  // Fetch applications on create
  // ---------------------------------------------------------------
  React.useEffect(() => {

    startPageLoader()

    fetchUserApplicationsApi()
      .then(data => {
        setApplications(data)
        stopPageLoader()
      })
      .catch(error => {
        console.log(error)
        stopPageLoader()
      })
  }, [])

  // ---------------------------------------------------------------
  // Handle application selection
  // Go to applications show page
  // ---------------------------------------------------------------
  const handleSelectApplication = application => {
    window.location.href = `/applications/${application.id}`
  }

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <div>
      <Container className="mt-3 mb-5">
        {
          pageLoader ||
          <div>
            <h4>Οι Αιτήσεις μου</h4>
            <Table striped bordered hover className="mt-4">
              <thead>
                <tr>
                  <th>Αρ. πρωτ.</th>
                  <th>Περιγραφή Πρόσκλησης/θέσης</th>
                  <th>Κατάσταση</th>
                  <th>Υποβολή</th>
                </tr>
              </thead>
              <tbody>
                {
                  applications.map(application => {
                    return (
                      <tr key={application.id} style={{ cursor: 'pointer' }} onClick={() => handleSelectApplication(application)}>
                        <td>{application.invitation.prot_aa}</td>
                        <td>
                          <>
                            {application.invitation.descr} <br />
                            {
                              application?.position.tmima ?
                                <>
                                  <span style={{ fontWeight: 'bold' }}>
                                    {application?.position.tmima.descr} ({application?.position.tmima.city})
                                  </span>
                                  <br />
                                </>
                                : null
                            }
                            {
                              application?.position.pedio ?
                                <>
                                  <span style={{ fontWeight: 'bold' }}>
                                    {application?.position.pedio}
                                  </span>
                                  <br />
                                </>
                                : null
                            }
                            {
                              application?.position.descr ?
                                <>
                                  <span style={{ fontWeight: 'bold' }}>
                                    {application?.position.descr}
                                  </span>
                                  <br />
                                </>
                                : null
                            }
                          </>
                        </td>
                        <td>
                          {application?.status_descr}
                        </td>
                        <td>
                          {application?.submitted_at ? formatDateTime(application?.submitted_at) : null}
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </div>
        }
      </Container>
    </div>
  )
}


// ---------------------------------------------------------------
// // Mount into dom
// ---------------------------------------------------------------
if (document.getElementById('applications-index')) {
  const root = ReactDOM.createRoot(document.getElementById('applications-index'))
  root.render(<ApplicationsIndex />)
}
