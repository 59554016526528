import React from 'react'
import { deleteInvitationFileApi, postInvitationFileApi } from '../../../../api/invitations'
import { getConfirm } from '../../../../components/Confirm'
import FilesTable from '../../../../components/FilesTable'
import SelectFileForm from '../../../../components/SelectFileForm'

export default function Files({ invitation, setInvitation }) {

    // ---------------------------------------------------------------
    // Select file handler
    // ---------------------------------------------------------------
    const handleSubmit = (fileDescription, file) => {
        postInvitationFileApi({
            invitation_id: invitation.id,
            descr: fileDescription
        }, file)
            .then(response => {
                setInvitation(response)
            })
            .catch(error => console.log(error))
    }

    // ---------------------------------------------------------------
    // Delete file handler
    // ---------------------------------------------------------------
    const handleDelete = (file) => {
        getConfirm(
            'Επιβεβαίωση διαγραφής αρχείου',
            `Να διαγραφεί το αρχείο "${file.descr}"?`,
            "Διαγραφή",
            'Ακύρωση',
        ).then(response => {
            if (response) {
                deleteInvitationFileApi(file)
                    .then(response => setInvitation(response))
                    .catch(error => console.log(error))
            }
        })
    }

    // ---------------------------------------------------------------
    // JSX
    // ---------------------------------------------------------------
    return (
        <div className="card mt-4">
            <div className="card-header" style={{ display: "flex", justifyContent: 'space-between' }}>
                <h5 className="card-title">Ψηφιακό Υλικό</h5>
                <SelectFileForm onSubmit={handleSubmit} />
            </div>
            <div className="card-body">
                {
                    invitation?.files?.length > 0
                        ?
                        <FilesTable files={invitation?.files} onDeleteFile={handleDelete} />
                        :
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div>Δεν έχουν επιλεγεί αρχεία</div>
                        </div>
                }
            </div>
        </div>
    )
}