import React from 'react'
import ReactDOM from 'react-dom/client'
import { Button } from 'react-bootstrap'
import { fetchApplicationApi } from '../../api/applications'
import ApplicationPreview from '../../components/ApplicationPreview'
import InvitationHeader from '../../components/InvitationHeader'
import { getConfirm } from '../../components/Confirm'
import useLoader from '../../hooks/useLoader'


export default function ApplicationShow() {

  // ---------------------------------------------------------------
  // Local state
  // ---------------------------------------------------------------
  const [application, setApplication] = React.useState()
  const { loader: pageLoader, startLoader: startPageLoader, stopLoader: stopPageLoader } = useLoader()


  // ---------------------------------------------------------------
  // Fetch application on mount 
  // /applications/{id}
  // ---------------------------------------------------------------
  React.useEffect(() => {

    startPageLoader()

    const url = window.location.href.split('/')
    const applicationId = url[url.length - 1]
    fetchApplicationApi(applicationId)
      .then(data => {
        setApplication(data)
        stopPageLoader()
      })
      .catch(error => {
        toast.error(error)
        stopPageLoader()
      })
  }, [])

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <div>
      {
        pageLoader ||
        <div>
          <h4 style={{ textAlign: 'center', color: '#429325', marginBottom: '20px' }}>
            ΠΡΟΒΟΛΗ ΑΙΤΗΣΗΣ
          </h4>
          <InvitationHeader invitation={application?.invitation} />
          <ApplicationPreview application={application} />

          {/* Edit button (αν είναι ανοιχτή η πρόσληση) */}
          <EditButton application={application} />
        </div>}
    </div>
  )
}

// -------------------------------------------------------------------
// Componet: Edit button
// -------------------------------------------------------------------
function EditButton({ application }) {

  // Handle edit button
  const handleEditApplication = () => {

    // Αν η αιτηση έχει οριστικοποιηθεί, μήνυμα επαναφοράς σε κατάσταση "ΠΡΟΣΩΡΙΝΗ"
    if (application?.status != 0) {
      getConfirm(
        'Επεξεργασία Αίτησης',
        'Η επεξεργασία της αίτησης θα την επαναφέρει στην κατάσταση "ΠΡΟΣΩΡΙΝΗ". Θέλετε να συνεχίσετε?',
        "Επεξεργασία",
        'Ακύρωση',
      ).then(response => {
        if (response) {
          window.location.href = `/applications/${application.id}/edit`
        }
      })
    }
    else {
      window.location.href = `/applications/${application.id}/edit`
    }
  }

  // JSX
  if (!application?.can_be_edited) return null
  return (
    <div
      style={{
        marginTop: '30px',
        display: 'flex',
      }}
    >
      <Button variant="success" onClick={handleEditApplication}>Επεξεργασία Αίτησης</Button>
    </div>
  )
}


// ---------------------------------------------------------------
// Mount into dom
// ---------------------------------------------------------------
if (document.getElementById('applications-show')) {
  const root = ReactDOM.createRoot(document.getElementById('applications-show'))
  root.render(<ApplicationShow />)
}