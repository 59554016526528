import React from 'react'
import { Button } from 'react-bootstrap'
import { deleteUserApi, updateUserApi } from '../../../../api/users'
import { getConfirm } from '../../../../components/Confirm'
import { styles } from '../../../../components/styles'
import StatusIcon from '../index/StatusIcon'
import Roles from './Roles'
import UserModalForm from '../index/UserModalForm'

export default function Stoixeia({ user, setUser }) {

  // ---------------------------------------------------------------
  // Local state and context
  // ---------------------------------------------------------------
  const [showForm, setShowForm] = React.useState(false)

  // ---------------------------------------------------------------
  // handle edit
  // ---------------------------------------------------------------
  const handleEdit = (values) => {
    setShowForm(false)
    updateUserApi(values)
      .then(response => setUser(response))
      .catch(error => console.log(error))
  }

  // ---------------------------------------------------------------
  // handle delete
  // ---------------------------------------------------------------
  const handleDelete = () => {
    getConfirm(
      'Διαγραφή Χρήστη',
      `Να διαγραφεί ο χρήστης ${user?.fullname}`,
      "Διαγραφή",
      'Ακύρωση',
    ).then(response => {
      if (response) {
        deleteUserApi(user)
          .then(response => {
            window.location.href = `/admin/users`
          })
          .catch(error => console.log(error))
      }
    })
  }

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <div className="card mt-4">
      <div className="card-header" style={{ display: "flex", justifyContent: 'space-between' }}>
        <h5 className="card-title">Στοιχεία Χρήστη</h5>
      </div>
      <div className="card-body">

        <div style={styles.section}>
          <div style={styles.label}>ΚΑΤΑΣΤΑΣΗ</div>
          <div style={styles.data}>
            <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
              <StatusIcon user={user} />
              {user?.status}
            </div>
          </div>
        </div>

        <div style={styles.section}>
          <div style={styles.label}>E-MAIL</div>
          <div style={styles.data}>{user?.email}</div>
        </div>

        <div style={styles.section}>
          <div style={styles.label}>ΕΠΩΝΥΜΟ</div>
          <div style={styles.data}>{user?.surname}</div>
        </div>

        <div style={styles.section}>
          <div style={styles.label}>ONOMA</div>
          <div style={styles.data}>{user?.firstname}</div>
        </div>

        <div style={styles.section}>
          <div style={styles.label}>ΠΑΤΡΩΝΥΜΟ</div>
          <div style={styles.data}>{user?.fathername || '---'}</div>
        </div>

        <div style={styles.section}>
          <div style={styles.label}>ΚΙΝΗΤΟ ΤΗΛΕΦΩΝΟ</div>
          <div style={styles.data}>{user?.mobil || '---'}</div>
        </div>

        <div style={styles.section}>
          <div style={styles.label}>Α.Φ.Μ.</div>
          <div style={styles.data}>{user?.afm || '---'}</div>
        </div>

        <div style={styles.section}>
          <div style={styles.label}>ΡΟΛΟΙ</div>
          <Roles user={user} />
        </div>

      </div>

      <div className="card-footer" style={{ display: 'flex', gap: '15px' }}>

        <Button size='sm' variant='primary' onClick={() => { setShowForm(true) }} >
          Επεξεργασία Στοιχείων Χρήστη
        </Button>

        {/* Αν μπορεί να διαγραφεί */}
        {
          user?.can_delete
            ?
            <Button size='sm' variant='danger' onClick={handleDelete}>Διαγραφή Χρήστη</Button>
            :
            null
        }
      </div>
      {/* Φόρμα επεξεργασίας (modal) */}
      <UserModalForm
        show={showForm}
        user={user}
        onSubmit={handleEdit}
        onCancel={() => setShowForm(false)}
      />
    </div >
  )
}
