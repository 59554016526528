import React from 'react'
import { Button, Table } from 'react-bootstrap'

export default function FilesTable({ files, onDeleteFile }) {

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <Table striped hover className='mt-4'>
      <thead>
        <tr>
          <th>Περιγραφή Αρχείου</th>
          <th>Τύπος</th>
          <th>Μέγεθος</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          files?.map(file => {
            return (
              <tr key={file.id}>
                <td><a href={file.url} target="_blank">{file.descr}</a></td>
                <td>{file.extension}</td>
                <td>{file.size}</td>
                <td style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    style={{ width: 140 }}
                    variant='outline-danger'
                    size='sm'
                    onClick={() => { onDeleteFile(file) }}
                  >
                    Διαγραφή Αρχείου
                  </Button>
                </td>
              </tr>
            )
          })
        }
      </tbody>
    </Table>
  )
}
