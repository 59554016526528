import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { MdClose } from 'react-icons/md'

export default function StatusIcon({ user }) {
  if (user?.isactive) {
    return <BsCheckLg color='green' size={18} />
  }
  else {
    return <MdClose color='red' size={24} />
  }

}
