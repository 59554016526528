import React from 'react'
import { Card, Table, Button } from 'react-bootstrap'
import { FaTrashAlt } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import { PositionsContext } from '../../../pages/admin/invitations/show/Positions'

export default function AdminPositionsTypeOne({ positions }) {
  const sxoles = []

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <div>
      {
        positions?.map(position => {
          if (!sxoles.includes(position.tmima.sxoli.id)) {
            sxoles.push(position.tmima.sxoli.id)
            return <RenderSxoli key={position.tmima.sxoli.id} sxoli={position.tmima.sxoli} positions={positions} />
          }
          else {
            return null
          }
        })
      }
    </div>
  )
}


// ********************************************************************
// Render Sxoli
// ********************************************************************
function RenderSxoli({ sxoli, positions }) {
  const tmimata = []  // rendered tmimata

  return (
    <div>
      <h5 style={{ color: '#093FA9' }} className="mt-4">{sxoli.descr}</h5>
      {
        positions.map(position => {
          if (position.tmima.sxoli.id == sxoli.id && !tmimata.includes(position.tmima.id)) {
            tmimata.push(position.tmima.id)
            return <RenderTmima key={position.tmima.id} tmima={position.tmima} positions={positions} />
          }
          else {
            return null
          }
        })
      }
    </div>
  )
}

// ********************************************************************
// Render tmima
// ********************************************************************
function RenderTmima({ tmima, positions }) {

  // Context for update and delete position
  const { handleEditPositionClick, handleDeletePosition } = React.useContext(PositionsContext)

  // JSX
  return (
    <Card className="mt-4">
      <Card.Header>
        <h5 style={{ color: '#705921' }}>{tmima.descr} ({tmima.city})</h5>
      </Card.Header>
      <Card.Body>
        <Table striped hover>
          <thead>
            <tr>
              <th style={{ width: 50 }}>A/A</th>
              <th style={{ width: 50 }}>Θέσεις</th>
              <th style={{ width: 500 }}>Επιστημονικό Πεδίο</th>
              <th>Μαθήματα</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              positions.map(position => {
                if (position.tmima.id == tmima.id) {
                  return (
                    <tr key={'pedio' + position.id}>
                      <td>{position.aa}</td>
                      <td>{position.theseis}</td>
                      <td>{position.pedio}</td>
                      <td>{position.descr}</td>
                      <td style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                          variant='outline-primary'
                          size='sm'
                          title="Επεξεργασία Θέσης"
                          onClick={() => { handleEditPositionClick(position) }}
                          style={{ marginRight: '5px' }}
                        >
                          <MdEdit />
                        </Button>
                        {
                          <Button
                            variant='outline-danger'
                            size='sm'
                            title="Διαγραφή Θέσης"
                            onClick={() => handleDeletePosition(position)}
                          >
                            <FaTrashAlt />
                          </Button>
                        }
                      </td>
                    </tr>
                  )
                }
                else {
                  return null
                }
              })
            }
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}
