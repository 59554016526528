import axios from "axios"

// Fetch all
export function fetchUsersApi() {
  return new Promise((resolve, reject) => {
    axios.get(`/api/admin/users`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject("Σφάλμα κατά την ανάκτηση των χρηστών")
      })
  })
}

// Fetch single user by id
export function fetchUserApi(userId) {
  return new Promise((resolve, reject) => {
    axios.get(`/api/admin/users/${userId}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject("Σφάλμα κατά την ανάκτηση των στοιχείων του χρήστη")
      })
  })
}

// Check user existance by email
export function checkUserByEmailApi(email) {
  return new Promise((resolve, reject) => {
    axios.post("/api/admin/users/check-by-email", { email })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        if (error.response.data.message) reject(error.response.data.message)
        else reject("Σφάλμα κατά τον έλεγχο ύπαρξης χρήστη")

      })
  })
}

// Create new user
export function createUserApi(user) {
  return new Promise((resolve, reject) => {
    axios.post("/api/admin/users", user)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        if (error.response.data.message) reject(error.response.data.message)
        else reject("Σφάλμα κατά την δημιουργία του χρήστη")

      })
  })
}

// update user
export function updateUserApi(user) {
  return new Promise((resolve, reject) => {
    axios.put(`/api/admin/users/${user.id}`, user)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        if (error.response.data.message) reject(error.response.data.message)
        else reject("Σφάλμα κατά την ενημέρωση των στοιχείων του χρήστη")

      })
  })
}

// delete user 
export function deleteUserApi(user) {
  return new Promise((resolve, reject) => {
    axios.delete(`/api/admin/users/${user.id}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        if (error.response.data.message) reject(error.response.data.message)
        else reject("Σφάλμα κατά την διαγραφή του χρήστη")
      })
  })
}

