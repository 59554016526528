// /invitations
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Container, Table } from 'react-bootstrap'
import { formatDateTime } from '../../utils'
import { fetchInvitationsApi } from '../../api/invitations'
import useLoader from '../../hooks/useLoader'


export default function InvitationsIndex() {

  // ---------------------------------------------------------------
  // Local state and context
  // ---------------------------------------------------------------
  const [invitations, setInvitations] = React.useState([])
  const { loader: pageLoader, startLoader: startPageLoader, stopLoader: stopPageLoader } = useLoader()

  // ---------------------------------------------------------------
  // Fetch invitations on create
  // ---------------------------------------------------------------
  React.useEffect(() => {
    startPageLoader()

    fetchInvitationsApi()
      .then(data => {
        setInvitations(data)
        stopPageLoader()
      })
      .catch(error => {
        console.log(error)
        stopPageLoader()
      })
  }, [])

  // ---------------------------------------------------------------
  // Handle invitation selection
  // ---------------------------------------------------------------
  const handleSelectInvitation = invitation => {
    window.location.href = `/invitations/${invitation.id}`
  }

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <div>
      <Container className="mt-3 mb-5">
        {
          pageLoader ||
          <div>
            <h4>Ανοιχτές προσκλήσεις εκδήλωσης ενδιαφέροντος</h4>
            <Table striped bordered hover className="mt-4">
              <thead>
                <tr>
                  <th>Αρ. Πρωτ.</th>
                  <th>Περιγραφή Πρόσκλησης</th>
                  <th>Προθεσμία</th>
                </tr>
              </thead>
              <tbody>
                {
                  invitations.map(invitation => {
                    return (
                      <tr key={invitation.id} style={{ cursor: 'pointer' }} onClick={() => handleSelectInvitation(invitation)}>
                        <td>{invitation.prot_aa}</td>
                        <td>{invitation.descr}</td>
                        <td>{formatDateTime(invitation.deadline)}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </div>}
      </Container>

    </div>
  )
}

// ---------------------------------------------------------------
// // Mount into dom
// ---------------------------------------------------------------
if (document.getElementById('invitations-index')) {
  const root = ReactDOM.createRoot(document.getElementById('invitations-index'))
  root.render(<InvitationsIndex />)
}