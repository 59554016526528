import React from 'react'
import ApplicationPreview from '../../ApplicationPreview'
import { ApplicationEditContext } from '../../../pages/applications/edit'
import { FormTabsContext } from './FormTabs'
import { Button } from 'react-bootstrap'
import { countAppDocs } from '../../../utils'
import { getConfirm } from '../../Confirm'
import { submitApplicationApi } from '../../../api/applications'

export default function SubmitTab() {

    // ---------------------------------------------------------------
    // local state and context
    // ---------------------------------------------------------------
    const [application, setApplication] = React.useContext(ApplicationEditContext)
    const { setActiveTab } = React.useContext(FormTabsContext)

    // ---------------------------------------------------------------
    // Submit application handler
    // ---------------------------------------------------------------
    const handleSubmitApplication = () => {
        getConfirm(
            'Υποβολή Αίτησης',
            'Μπορείτε να τροποποιήσετε τα στοιχεία της αίτησης μετά την υποβολή και πριν την καταληκτική ημερομηνία υποβολής.',
            "Υποβολή",
            'Ακύρωση',
        ).then(response => {
            if (response) {
                submitApplicationApi(application)
                    .then(response => {
                        // Redirect to applications show page
                        window.location.replace(`/applications/${application.id}`)
                    })
                    .catch(error => console.log(error))
            }
        })
    }

    // ---------------------------------------------------------------
    // JSX
    // ---------------------------------------------------------------
    return (
        <div>
            <h5 style={{ textAlign: 'center', color: '#093FA9', marginBottom: '20px', marginTop: '20px' }}>
                ΠΡΟΕΠΙΣΚΟΠΗΣΗ ΑΙΤΗΣΗΣ
            </h5>
            <ApplicationPreview application={application} />
            <DocumentsAlert documents={application?.documents} />

            {/* buttons */}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 30 }}>
                <Button variant="primary" onClick={() => setActiveTab(3)}>Προηγούμενη Σελίδα</Button>
                <SubmitButton application={application} onSubmit={handleSubmitApplication} />
            </div>
        </div>
    )
}


// ----------------------------------------------------------------------------------------------
// DocumentsAlert
// Μύνημα αν δεν έχουν υποβληθεί όλα τα απαιτούμενα δικαιολογητικά
// ---------------------------------------------------------------------------------------------- 
function DocumentsAlert({ documents }) {

    const { required, submitted } = countAppDocs(documents)

    if (required != submitted) {
        return (
            <div style={{ color: 'red', fontSize: 18, fontWeight: 700, textAlign: 'center' }}>
                {`Υπάρχουν ακόμη ${required - submitted} υποχρεωτικά δικαιολογητικά που δεν έχουν υποβληλθεί!!!`}
                <br />
                Μεταβείτε στην ενότητα των δικαιολογητικών για να τα υποβάλλετε.
                <br />
                Αν δεν υποβληθούν όλα τα  υποχρεωτικά δικαιολογητικά δεν μπορεί να γίνει οριστικοποίηση της αίτησης.
            </div>
        )
    }

    // All ok
    return null
}

// ----------------------------------------------------------------------------------------------
// Submit button
// Check for required documents
// ---------------------------------------------------------------------------------------------- 
function SubmitButton({ application, onSubmit }) {
    const { required, submitted } = countAppDocs(application?.documents)
    if (required == submitted) {
        return <Button variant="primary" onClick={onSubmit}>Υποβολή Αίτησης</Button>
    }
    else {
        return null
    }
}