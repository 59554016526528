import React from 'react'
import { useFormik } from 'formik'
import { Form, Modal, Button } from 'react-bootstrap'

export default function TextModalForm({
  show,
  title,
  label,
  data,
  required = true,
  maxLength = 255,
  onSubmit,
  onCancel }) {

  // ---------------------------------------------------------------
  // Reset form
  // ---------------------------------------------------------------
  React.useEffect(() => {
    if (!show) {
      formik.resetForm() // reset on close
    }
  }, [show])

  // ---------------------------------------------------------------
  // Form validation
  // ---------------------------------------------------------------
  const validateForm = values => {

    const errors = {}

    if (required && !values.field) {
      errors.field = 'Υποχρεωτικό πεδίο'
    }

    // Μήκος πεδίου
    if (values.field && values.field.length > maxLength) {
      errors.field = `Πληκτρολογείστε μέχρι ${maxLength} χαρακτήρες`;
    }

    // Επιστροφή
    return errors
  }

  // ---------------------------------------------------------------
  // Initial form values
  // ---------------------------------------------------------------
  const initialValues = {
    field: data
  }

  // ---------------------------------------------------------------
  // Formik hook
  // ---------------------------------------------------------------
  const formik = useFormik({
    initialValues,
    onSubmit: onSubmit,
    validate: validateForm,
    enableReinitialize: true,
  })

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <Modal show={show} backdrop="static" keyboard={false} size="lg">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header >
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3" controlId="prot_aa">
            <Form.Label>{required ? `${label} (*)` : label}</Form.Label>
            <Form.Control
              name="field"
              type="text"
              value={formik.values.field || ''}
              onChange={formik.handleChange}
              isInvalid={formik.touched.field && formik.errors.field}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.field}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={formik.handleSubmit}>Καταχώρηση</Button>
        <Button variant="danger" onClick={onCancel}>Ακύρωση</Button>
      </Modal.Footer>
    </Modal>
  )
}
