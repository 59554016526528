import React from 'react'
import { BsCheckLg } from 'react-icons/bs'
import { MdClose } from 'react-icons/md'

export default function PublishedIcon({ invitation }) {
    if (invitation?.published) {
        return <BsCheckLg color='green' size={18} />
    }
    else {
        return <MdClose color='red' size={24} />
    }

}
