import axios from "axios"

// Create new member
export function createMemberApi(member) {
    return new Promise((resolve, reject) => {
        axios.post("/api/admin/members", member)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                if (error.response.data.message) reject(error.response.data.message)
                else reject("Σφάλμα κατά την δημιουργία του μέλους")

            })
    })
}


// update member
export function updateMemberApi(member) {
    return new Promise((resolve, reject) => {
        axios.put(`/api/admin/members/${member.id}`, member)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                if (error.response.data.message) reject(error.response.data.message)
                else reject("Σφάλμα κατά την ενημέρωση των στοιχείων του μέλους")

            })
    })
}

// delete member from invitation
export function deleteMemberApi(member) {
    return new Promise((resolve, reject) => {
        axios.delete(`/api/admin/members/${member.id}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                if (error.response.data.message) reject(error.response.data.message)
                else reject("Σφάλμα κατά την διαγραφή του μέλους")
            })
    })
}
