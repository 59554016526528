import axios from "axios"

// Fetch all
export function fetchSxolesApi() {
  return new Promise((resolve, reject) => {
    axios.get(`/api/sxoles`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject("Σφάλμα κατά την ανάκτηση των σχολών")
      })
  })
}

// Fetch single sxoli by id
export function fetchSxoliApi(sxoliId) {
  return new Promise((resolve, reject) => {
    axios.get(`/api/sxoles/${sxoliId}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject("Σφάλμα κατά την ανάκτηση των στοιχείων της σχολής")
      })
  })
}

// Create new sxoli
export function createSxoliApi(sxoli) {
  return new Promise((resolve, reject) => {
    axios.post("/api/sxoles", sxoli)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        if (error.response.data.message) reject(error.response.data.message)
        else reject("Σφάλμα κατά την δημιουργία της σχολής")

      })
  })
}

// update sxoli
export function updateSxoliApi(sxoli) {
  return new Promise((resolve, reject) => {
    axios.put(`/api/sxoles/${sxoli.id}`, sxoli)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        if (error.response.data.message) reject(error.response.data.message)
        else reject("Σφάλμα κατά την ενημέρωση των στοιχείων της σχολής")

      })
  })
}

// delete sxoli
export function deleteSxoliApi(sxoli) {
  return new Promise((resolve, reject) => {
    axios.delete(`/api/sxoles/${sxoli.id}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        if (error.response.data.message) reject(error.response.data.message)
        else reject("Σφάλμα κατά την διαγραφή της σχολής")
      })
  })
}