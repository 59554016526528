import React from 'react'
import { Button, ButtonGroup, Form, InputGroup } from 'react-bootstrap'

export default function SelectFileForm({ onSubmit }) {

  // ---------------------------------------------------------------
  // Local state and context
  // ---------------------------------------------------------------
  const [showForm, setShowForm] = React.useState(false)
  const [fileDescription, setFileDescription] = React.useState()

  // Input file ref
  const inputFileRef = React.useRef(null)

  // ---------------------------------------------------------------
  // Handle new file button click
  // ---------------------------------------------------------------
  const handleNewFile = () => {
    setFileDescription('')
    setShowForm(true)
  }

  // ---------------------------------------------------------------
  // Handle select file button click
  // ---------------------------------------------------------------
  const handleSelectFile = () => {
    inputFileRef.current.value = null
    inputFileRef.current.click()
  }

  // ---------------------------------------------------------------
  // Handle submit file
  // ---------------------------------------------------------------
  const handleSubmit = (e) => {
    const file = e.target.files[0]
    if (!file) return // cancel pressed
    onSubmit(fileDescription, file)
    setShowForm(false)
  }

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------

  return (
    <div>
      {showForm
        ?
        <InputGroup>
          <Form.Control
            size="sm"
            name="filename"
            type="text"
            value={fileDescription}
            onChange={(e) => setFileDescription(e.target.value)}
            placeholder="Περιγραφή του αρχείου..."
          />
          <ButtonGroup size="sm">
            <Button
              size="sm"
              variant="success"
              disabled={!Boolean(fileDescription)}
              onClick={handleSelectFile}
            >
              Επιλογή
            </Button>
            <Button
              size="sm"
              variant="danger"
              onClick={(() => setShowForm(false))}
            >
              Ακύρωση
            </Button>
          </ButtonGroup>
        </InputGroup>
        :
        <Button
          variant="success"
          size="sm"
          onClick={handleNewFile}
        >
          Επιλογή Νέου Αρχείου
        </Button>
      }
      {/* Hidden form to submit file */}
      <input type='file' ref={inputFileRef} style={{ display: 'none' }} onChange={(e) => handleSubmit(e)} />
    </div>
  )
}
