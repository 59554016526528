import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Members from './Members'
import Stoixeia from './Stoixeia'
import Documents from './Documents'
import Files from './Files'
import Positions from './Positions'
import Applications from './Applications'

export default function AdminView({ invitation, setInvitation }) {
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <h5 className="card-title" style={invitation?.published ? { color: 'green' } : { color: 'red' }}>
                    {invitation?.published ? "ΔΗΜΟΣΙΕΥΜΕΝΗ" : "ΜΗ ΔΗΜΟΣΙΕΥΜΕΝΗ"}
                </h5>
            </div>
            <Tabs className="mt-3">
                <TabList>
                    <Tab>Στοιχεία Πρόσκλησης</Tab>
                    <Tab>Διαχειριστές-Μέλη</Tab>
                    <Tab>Δικαιολογητικά</Tab>
                    <Tab>Ψηφιακό Υλικό</Tab>
                    <Tab>Θέσεις</Tab>
                    <Tab>Αιτήσεις</Tab>
                </TabList>
                <TabPanel>
                    <Stoixeia invitation={invitation} setInvitation={setInvitation} />
                </TabPanel>
                <TabPanel>
                    <Members invitation={invitation} setInvitation={setInvitation} />
                </TabPanel>
                <TabPanel>
                    <Documents invitation={invitation} setInvitation={setInvitation} />
                </TabPanel>
                <TabPanel>
                    <Files invitation={invitation} setInvitation={setInvitation} />
                </TabPanel>
                <TabPanel>
                    <Positions invitation={invitation} setInvitation={setInvitation} />
                </TabPanel>
                <TabPanel>
                    <Applications invitation={invitation} setInvitation={setInvitation} />
                </TabPanel>
            </Tabs>
        </>
    )
}
