import React from 'react'
import { useFormik } from 'formik'
import { Form, Modal, Button } from 'react-bootstrap'
import DatePicker, { registerLocale } from "react-datepicker"
import { formatFnsDbDate, formatFnsDbDatetime, parseDate } from '../../../../utils'
import el from 'date-fns/locale/el';

registerLocale('el', el)

export default function InvitationModalForm({ show, invitation, onSubmit, onCancel }) {

  // ---------------------------------------------------------------
  // Reset form
  // ---------------------------------------------------------------
  React.useEffect(() => {
    if (!show) {
      formik.resetForm() // reset on close
    }
  }, [show])

  // ---------------------------------------------------------------
  // Form validation
  // ---------------------------------------------------------------
  const requiredFields = [
    'type', 'prot_date', 'prot_aa', 'descr', 'deadline',
  ]

  const validateForm = values => {

    const errors = {}

    // Check for required fields
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = 'Υποχρεωτικό πεδίο'
      }
    })

    // Μήκος πεδίων
    if (values.prot_aa && values.prot_aa.length > 100) errors.prot_aa = "Πληκτρολογείστε μέχρι 100 χαρακτήρες"
    if (values.descr && values.descr.length > 1000) errors.descr = "Πληκτρολογείστε μέχρι 1000 χαρακτήρες"

    // Επιστροφή
    return errors
  }

  // ---------------------------------------------------------------
  // Initial form values
  // ---------------------------------------------------------------
  const initialValues = {
    id: invitation?.id || -1,
    type: invitation?.type || '',
    prot_date: invitation?.prot_date || null,
    prot_aa: invitation?.prot_aa || '',
    descr: invitation?.descr || '',
    deadline: invitation?.deadline || null,
    published: invitation?.published || false,
  }

  // ---------------------------------------------------------------
  // Formik hook
  // ---------------------------------------------------------------
  const formik = useFormik({
    initialValues,
    onSubmit: onSubmit,
    validate: validateForm,
    enableReinitialize: true,
  })


  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <Modal show={show} backdrop="static" keyboard={false} size="lg">
      <Modal.Header>
        <Modal.Title>{invitation ? "Επεξεργασία Πρόσκλησης" : "Νέα Πρόσκληση"}</Modal.Title>
      </Modal.Header >
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>

          {/* type */}
          <Form.Group className="mb-3" controlId="type">
            <Form.Label>ΤΥΠΟΣ ΠΡΟΣΚΛΗΣΗΣ (*)</Form.Label>
            <Form.Control
              as="select"
              value={formik.values.type || ''}
              onChange={e => formik.setFieldValue('type', e.target.value)}
              onBlur={() => formik.setFieldTouched('type')}
              isInvalid={formik.touched.type && formik.errors.type}
            >
              <option value="">(Επιλέξτε ...)</option>
              <option value={1}>Διδακτική Εμπειρία</option>
              <option value={2}>Σύμβαση Μίσθωσης Έργου</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {formik.errors.type}
            </Form.Control.Feedback>
          </Form.Group>

          {/* prot_aa */}
          <Form.Group className="mb-3" controlId="prot_aa">
            <Form.Label>ΑΡΙΘΜΟΣ ΠΡΩΤΟΚΟΛΛΟΥ (*)</Form.Label>
            <Form.Control
              name="prot_aa"
              type="text"
              value={formik.values.prot_aa || ''}
              onChange={formik.handleChange}
              isInvalid={formik.touched.prot_aa && formik.errors.prot_aa}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.prot_aa}
            </Form.Control.Feedback>
          </Form.Group>

          {/* prot_date */}
          <div className="form-group mb-3">
            <label htmlFor="prot_date" className="form-label">ΗΜΕΡΟΜΗΝΙΑ ΠΡΩΤΟΚΟΛΛΟΥ (*)</label>
            <DatePicker
              selected={formik.values.prot_date ? parseDate(formik.values.prot_date) : null}
              onChange={dt => formik.setFieldValue('prot_date', formatFnsDbDate(dt))}
              // onSelect={() => formik.setFieldValue('birthdate', formatFnsDbDate(dt))}
              locale='el'
              dateFormat='dd/MM/yyyy'
              className='form-control'
              placeholderText='Eπιλέξτε ή πληκτρολογείστε ...'
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={80}
            />
            {formik.touched.prot_date
              ?
              <Form.Control.Feedback type="invalid" style={{ display: 'block', width: '100%', marginTop: '0.25rem' }}>
                {formik.errors.prot_date}
              </Form.Control.Feedback>
              : null
            }
          </div>

          {/* descr */}
          <Form.Group className="mb-3" controlId="descr">
            <Form.Label>ΠΕΡΙΓΡΑΦΗ ΠΡΟΣΚΛΗΣΗΣ (*)</Form.Label>
            <Form.Control
              name="descr"
              as="textarea"
              rows="4"
              value={formik.values.descr || ''}
              onChange={formik.handleChange}
              isInvalid={formik.touched.descr && formik.errors.descr}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.descr}
            </Form.Control.Feedback>
          </Form.Group>

          {/* deadline */}
          <div className="form-group mb-3">
            <label htmlFor="deadline" className="form-label">ΠΡΟΘΕΣΜΙΑ ΥΠΟΒΟΛΗΣ ΑΙΤΗΣΕΩΝ (*)</label>
            <DatePicker
              selected={formik.values.deadline ? parseDate(formik.values.deadline) : null}
              onChange={dt => formik.setFieldValue('deadline', formatFnsDbDatetime(dt))}
              // onSelect={() => formik.setFieldValue('birthdate', formatFnsDbDate(dt))}
              locale='el'
              dateFormat='dd/MM/yyyy, HH:mm'
              className='form-control'
              placeholderText='Eπιλέξτε ή πληκτρολογείστε ...'
              showTimeSelect
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={80}
              timeFormat="HH:mm"
              timeCaption="Ώρα"
            />
            {formik.touched.deadline
              ?
              <Form.Control.Feedback type="invalid" style={{ display: 'block', width: '100%', marginTop: '0.25rem' }}>
                {formik.errors.deadline}
              </Form.Control.Feedback>
              : null
            }
          </div>

          {/* published */}
          <Form.Group className="mb-3" controlId="published">
            <Form.Check
              label="ΔΗΜΟΣΙΕΥΣΗ"
              type="checkbox"
              id="published"
              name="published"
              checked={formik.values.published || false}
              onChange={formik.handleChange}
              isInvalid={formik.touched.published && formik.errors.published}
            />
          </Form.Group>

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={formik.handleSubmit}>Καταχώρηση</Button>
        <Button variant="danger" onClick={onCancel}>Ακύρωση</Button>
      </Modal.Footer>
    </Modal >
  )
}
