import React from 'react'
import { Table, Button } from 'react-bootstrap'
import { ApplicationEditContext } from '../../../pages/applications/edit'
import { FormTabsContext } from './FormTabs'
import { GiCheckMark } from 'react-icons/gi'
import { AiOutlineClose } from 'react-icons/ai'
import { deleteApplicationDocumentApi, postApplicationDocumentApi } from '../../../api/applications'
import { getConfirm } from '../../Confirm'
import { countAppDocs } from '../../../utils'
import useLoader from '../../../hooks/useLoader'

export default function DocumentsTab() {

  // ---------------------------------------------------------------
  // Local state and context
  // ---------------------------------------------------------------
  const [application, setApplication] = React.useContext(ApplicationEditContext)
  const { setActiveTab } = React.useContext(FormTabsContext)
  const [selectedDocument, setSelectedDocument] = React.useState(); // for upload
  const { startLoader, stopLoader } = useLoader()

  // Input file ref
  const inputFileRef = React.useRef(null)

  // handle file selection
  const handleSelectFile = (document) => {
    setSelectedDocument(document)
    inputFileRef.current.value = null
    inputFileRef.current.click()
  }

  // Handle file submit
  const handleSubmitFile = (e) => {
    startLoader()
    const file = e.target.files[0]
    if (!file) return // cancel pressed
    postApplicationDocumentApi(selectedDocument, file)
      .then(response => {
        setApplication(response)
        stopLoader()
      })
      .catch(error => {
        console.log(error)
        stopLoader()
      })
  }

  // Handle delete file
  const handleDeleteFile = (document) => {
    getConfirm(
      'Επιβεβαίωση διαγραφής δικαιολογητικού',
      `Να διαγραφεί το αρχείο του δικαιολογητικού "${document.document.descr}"?`,
      "Διαγραφή",
      'Ακύρωση',
    ).then(response => {
      if (response) {
        startLoader()
        deleteApplicationDocumentApi(document)
          .then(response => {
            setApplication(response)
            stopLoader()
          })
          .catch(error => {
            console.log(error)
            stopLoader()
          })
      }
    })
  }

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <div className="mt-3">
      <p style={{ fontWeight: 700 }}>
        Αν δεν υποβληθούν τα υποχρεωτικά δικαιολογητικά δεν μπορεί να γίνει οριστικοποιηση της αίτησης.<br />
        Για κάθε δικαιολογητικό ανεβάζετε ένα μοναδικό αρχείο.
        Αν τα έγγραφα είναι περισσότερα του ενός θα πρέπει να τα συμπιέσετε σε ένα αρχείο ".zip".<br />
        Μέγιστο επιτρεπόμενο μέγεθος αρχείου: 75MΒ. <br />
      </p>
      <Table striped hover className="mt-4">
        <thead>
          <tr>
            <th>Περιγραφή Δικαιολογητικού</th>
            <th style={{ textAlign: 'center' }}>Απαιτείται</th>
            <th style={{ textAlign: 'center' }}>Υποβλήθηκε</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            application?.documents.map(doc => {
              return (
                <tr key={doc.id}>
                  <td><DocumentDescriptionCell document={doc} /></td>
                  <td style={{ textAlign: 'center' }}><DocumentRequiredCell document={doc} /></td>
                  <td style={{ textAlign: 'center' }}><DocumentSubmittedCell document={doc} /></td>
                  <td style={{ textAlign: 'right' }}>
                    <DocumentButtonCell document={doc} onSelectFile={handleSelectFile} onDeleteFile={handleDeleteFile} />
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
      <DocumentsAlert documents={application?.documents} />

      {/* Hidden form to submit file */}
      <input type='file' ref={inputFileRef} style={{ display: 'none' }} onChange={(e) => handleSubmitFile(e)} />

      {/* buttons */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 30 }}>
        <Button variant="primary" onClick={() => setActiveTab(1)}>Προηγούμενη Σελίδα</Button>
        <Button variant="primary" onClick={() => setActiveTab(3)}>Επόμενη Σελίδα</Button>
      </div>

    </div>
  )
}


// ----------------------------------------------------------------------------------------------
// Document required cell
// ---------------------------------------------------------------------------------------------- 
function DocumentRequiredCell({ document }) {
  if (document.document.required) {
    return (
      <div style={{ fontWeight: 700 }}>ΝΑΙ</div>
    )
  }
  else {
    return (
      <div>ΟΧΙ</div>
    )
  }
}

// ----------------------------------------------------------------------------------------------
// Document submitted cell
// ---------------------------------------------------------------------------------------------- 
function DocumentSubmittedCell({ document }) {
  if (document.url) {
    return (
      <GiCheckMark color='#198754' size={24} />
    )
  }
  else {
    return (
      <AiOutlineClose color='#dc3545' size={26} />
    )
  }
}


// ----------------------------------------------------------------------------------------------
// Document description cell
// ---------------------------------------------------------------------------------------------- 
function DocumentDescriptionCell({ document }) {
  if (document.url) {
    return (
      <a href={document.url} target="_blank">{document.document.descr}</a>
    )
  }
  else {
    return (
      <div>{document.document.descr}</div>
    )
  }
}

// ----------------------------------------------------------------------------------------------
// Document Button Cell
// ---------------------------------------------------------------------------------------------- 
function DocumentButtonCell({ document, onSelectFile, onDeleteFile }) {
  // Αν έχει υποβληθεί το δικαιολογητικό - κουμπί διαγραφής
  // Διαφορετικά κουμπί υποβολής
  if (document.url) {
    return (
      <Button style={{ width: 140 }} size='sm' variant="outline-danger" onClick={() => onDeleteFile(document)}>Διαγραφή Αρχείου</Button >
    )
  }
  else {
    return (
      <Button style={{ width: 140 }} size='sm' variant="outline-success" onClick={() => onSelectFile(document)}>Υποβολή Αρχείου</Button>
    )
  }
}

// ----------------------------------------------------------------------------------------------
// DocumentsAlert
// Μύνημα αν δεν έχουν υποβληθεί όλα τα απαιτούμενα δικαιολογητικά
// ---------------------------------------------------------------------------------------------- 
function DocumentsAlert({ documents }) {

  const { required, submitted } = countAppDocs()

  var requiredDocs = 0

  if (required != submitted > 0) {
    return (
      <div style={{ color: 'red', fontSize: 16, fontWeight: 700 }}>
        {`Υπάρχουν ακόμη ${required - submitted} υποχρεωτικά δικαιολογητικά που δεν έχουν υποβληλθεί!!!`}
      </div>
    )
  }

  // All ok
  return null
}