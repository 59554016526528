import React from 'react'
import { Button, ButtonGroup, Form, InputGroup, Table } from 'react-bootstrap'
import { deleteApplicationFileApi, postApplicationFileApi } from '../../../api/applications'
import { ApplicationEditContext } from '../../../pages/applications/edit'
import { FormTabsContext } from './FormTabs'
import { getConfirm } from '../../Confirm'
import FilesTable from '../../FilesTable'
import SelectFileForm from '../../SelectFileForm'
import useLoader from '../../../hooks/useLoader'


export default function FilesTab() {

  // ---------------------------------------------------------------
  // Local state and context
  // ---------------------------------------------------------------
  const [application, setApplication] = React.useContext(ApplicationEditContext)
  const { setActiveTab } = React.useContext(FormTabsContext)
  const { startLoader, stopLoader } = useLoader()

  // ---------------------------------------------------------------
  // Handle submit file
  // ---------------------------------------------------------------
  const handleSubmitFile = (fileDescription, file) => {
    startLoader()
    postApplicationFileApi({
      application_id: application.id,
      descr: fileDescription
    }, file)
      .then(response => {
        setApplication(response)
        stopLoader()
      })
      .catch(error => {
        console.log(error)
        stopLoader()
      })
  }

  // ---------------------------------------------------------------
  // Delete file click handler
  // ---------------------------------------------------------------
  const handleDeleteFile = applicationFile => {

    getConfirm(
      'Επιβεβαίωση διαγραφής αρχείου',
      `Να διαγραφεί το αρχείο "${applicationFile.descr}"?`,
      "Διαγραφή",
      'Ακύρωση',
    ).then(response => {
      if (response) {
        startLoader()
        deleteApplicationFileApi(applicationFile)
          .then(response => {
            setApplication(response)
            stopLoader()
          })
          .catch(error => {
            console.log(error)
            stopLoader()
          })
      }
    })
  }


  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <div className="mt-3">
      <p style={{ fontWeight: 700 }}>
        Στην ενότητα αυτή μπορείτε να υποβάλλεται οποιαδήποτε συμπληρωματικά δικαιολογητικά δίνοντας μία αντιπροσωπευτική περιγραφή για το καθένα.
      </p>
      <SelectFileForm onSubmit={handleSubmitFile} />
      <FilesTable files={application?.files} onDeleteFile={handleDeleteFile} />

      {/* buttons */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 30 }}>
        <Button variant="primary" onClick={() => setActiveTab(2)}>Προηγούμενη Σελίδα</Button>
        <Button variant="primary" onClick={() => setActiveTab(4)}>Επόμενη Σελίδα</Button>
      </div>
    </div >


  )
}



