import React from 'react'
import DataTable from 'react-data-table-component'
import { setProtokolloApi } from '../../../../api/applications'
import { formatDate, formatDateTime } from '../../../../utils'
import ProtokolloModalForm from './ProtokolloModalForm'
import ApplicationPreview from '../../../../components/ApplicationPreview'
import ApplicationActions from './ApplicationActions'
import InvitationFiles from '../../../../components/InvitationFiles'

export default function SupervisorView({ invitation, setInvitation }) {

  const columns = [
    {
      name: 'Ονοματεπώνυμο',
      selector: row => row.user.fullname,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Τμήμα',
      selector: row => `${row.position?.tmima?.descr} (${row.position?.tmima?.city})`,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Κατάσταση',
      selector: row => row.status_descr,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Τελευταία Υποβολή',
      selector: row => row.submitted_at,
      format: row => row.submitted_at ? formatDateTime(row.submitted_at) : '',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Αρ. Πρωτ.',
      selector: row => row?.prot_aa || '',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Ημ/νία Πρωτ.',
      selector: row => row?.prot_date,
      format: row => row ? formatDate(row?.prot_date) : '',
      sortable: true,
      wrap: true,
    },
    {
      name: '',
      cell: row => <ApplicationActions application={row} onProtokollo={handleProtokolloClick} />,
      sortable: false,
      right: true,
      width: '180px',
    },
  ]

  // ---------------------------------------------------------------
  // Local state and context
  // ---------------------------------------------------------------
  const [showProtokolloForm, setShowProtokolloForm] = React.useState(false)
  const [selectedApplication, setSelectedApplication] = React.useState()

  // ---------------------------------------------------------------
  // handle protokollo click button
  // ---------------------------------------------------------------
  const handleProtokolloClick = application => {
    setSelectedApplication(application)
    setShowProtokolloForm(true)
  }

  // ---------------------------------------------------------------
  // Submit protokollo
  // ---------------------------------------------------------------
  const handleProtokolloSubmit = values => {
    const data = {
      application_id: selectedApplication.id,
      prot_aa: values.prot_aa,
      prot_date: values.prot_date
    }
    setProtokolloApi(data)
      .then(response => setInvitation(response))
      .catch(error => console.log(error))


    setShowProtokolloForm(false)
  }

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <div>
      <InvitationFiles files={invitation?.files} />

      <div className="card mt-4">
        <div className="card-header" style={{ display: "flex", justifyContent: 'space-between' }}>
          <h5 className="card-title">Αιτήσεις</h5>
        </div>
        <div className="card-body">
          <DataTable
            columns={columns}
            data={invitation?.applications}
            defaultSortFieldId={4}
            defaultSortAsc={false}
            striped={true}
            pagination
            paginationComponentOptions={{
              rowsPerPageText: 'Εγγραφές ανά σελίδα',
              rangeSeparatorText: 'από',
              selectAllRowsItem: true,
              selectAllRowsItemText: 'Όλα',
            }}
            theme='default'
            noDataComponent="Δεν υπάρχουν καταχωρημένες αιτήσεις στην πρόσκληση"
            highlightOnHover
            expandableRows
            expandableRowsComponent={ExpandableApplication}
            expandOnRowClicked
          />
        </div>
        <ProtokolloModalForm
          show={showProtokolloForm}
          onSubmit={handleProtokolloSubmit}
          onCancel={() => setShowProtokolloForm(false)}
        />
      </div>
    </div>
  )
}



// ********************************************************
// Expandable component
// ********************************************************
function ExpandableApplication({ data }) {
  return (
    <div>
      <h4 style={{ textAlign: 'center', color: '#429325', marginBottom: '20px', marginTop: '20px' }}>
        ΠΡΟΒΟΛΗ ΑΙΤΗΣΗΣ
      </h4>
      <ApplicationPreview application={data} />
    </div>
  )
}