import React from 'react'
import { Button, Container } from 'react-bootstrap'
import ReactDOM from 'react-dom/client'
import { MdEdit } from 'react-icons/md'
import { deleteSxoliApi, fetchSxoliApi, updateSxoliApi } from '../../../../api/sxoles'
import { getConfirm } from '../../../../components/Confirm'
import TextModalForm from '../../../../components/TextModalForm'
import useLoader from '../../../../hooks/useLoader'
import Tmimata from './Tmimata'

export default function Show() {

  // ---------------------------------------------------------------
  // Local state and context
  // ---------------------------------------------------------------
  const [sxoli, setSxoli] = React.useState()
  const [showSxoliForm, setShowSxoliForm] = React.useState(false)
  const { loader: pageLoader, startLoader: startPageLoader, stopLoader: stopPageLoader } = useLoader()

  // ---------------------------------------------------------------
  // Get sxoli_id from url and fetch it
  // ---------------------------------------------------------------
  React.useEffect(() => {

    startPageLoader()

    const url = window.location.href.split('/')
    const sxoliId = url[url.length - 1]

    // Fetch sxoli
    fetchSxoliApi(sxoliId)
      .then(data => {
        setSxoli(data)
        stopPageLoader()
      })
      .catch(error => {
        console.log(error)
        stopPageLoader()
      })

  }, [])

  // ---------------------------------------------------------------
  // handle update sxoli
  // ---------------------------------------------------------------
  const handleSxoliUpdate = data => {
    const newSxoli = {
      id: sxoli.id,
      descr: data.field
    }
    updateSxoliApi(newSxoli)
      .then(response => setSxoli(response))
      .catch(error => console.log(error))

    setShowSxoliForm(false)
  }

  // ---------------------------------------------------------------
  // handle delete sxoli
  // ---------------------------------------------------------------
  const handleDeleteSxoli = () => {
    getConfirm(
      'Διαγραφή Σχολής',
      `Να διαγραφεί η σχολή "${sxoli?.descr}"`,
      "Διαγραφή",
      'Ακύρωση',
    ).then(response => {
      if (response) {
        deleteSxoliApi(sxoli)
          .then(response => {
            window.location.href = `/admin/sxoles`
          })
          .catch(error => console.log(error))
      }
    })
  }

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <Container className="mt-4 mb-5">
      {
        pageLoader ||
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignContent: 'center' }}>
              <h4 style={{ marginBottom: 0 }}>{sxoli?.descr}</h4>
              <Button
                size="sm"
                variant='outline-primary'
                style={{ marginLeft: 15 }}
                title="Επεξεργασία ονόματος"
                onClick={() => setShowSxoliForm(true)}
              >
                <MdEdit size={22} />
              </Button>
            </div>
            {
              sxoli?.tmimata.length == 0 &&
              <Button size='sm' variant='danger' onClick={handleDeleteSxoli}>Διαγραφή Σχολής</Button>
            }
          </div>
          <hr style={{ height: 1, color: 'black', backgroundColor: 'black' }} />
          <Tmimata sxoli={sxoli} setSxoli={setSxoli} />
          <TextModalForm
            show={showSxoliForm}
            title="Επεξεργασία Σχολής"
            label="Περιγραφή Σχολής"
            data={sxoli?.descr}
            required={true}
            maxLength={255}
            onSubmit={handleSxoliUpdate}
            onCancel={() => setShowSxoliForm(false)}
          />
        </div>
      }
    </Container >
  )
}

// ---------------------------------------------------------------
// Mount into dom
// ---------------------------------------------------------------
if (document.getElementById('admin-sxoles-show')) {
  const root = ReactDOM.createRoot(document.getElementById('admin-sxoles-show'))
  root.render(<Show />)
}