import React from 'react'
import ReactDOM from 'react-dom/client'
import { Container, Button } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { fetchInvitationsApi, postInvitationApi } from '../../../../api/invitations'
import { formatDateTime } from '../../../../utils'
import InvitationModalForm from './InvitationModalForm'
import { fetchConnectedUserApi } from '../../../../api/auth'
import PublishedIcon from './PublishedIcon'
import useLoader from '../../../../hooks/useLoader'

// Datatabble columns
const columns = [
  {
    name: 'Δημοσίευση',
    selector: row => row.published,
    format: row => <PublishedIcon invitation={row} />,
    sortable: true,
    sortFunction: (a, b) => a.published > b.published,
    width: '130px'
  },
  {
    name: 'Αρ. πρωτ.',
    selector: row => row.prot_aa,
    sortable: true,
    width: '130px'
  },
  {
    name: 'Περιγραφή Πρόσκλησης',
    selector: row => row.descr,
    sortable: true,
    wrap: true
  },
  {
    name: 'Τύπος',
    selector: row => row.type_descr,
    sortable: true,
  },
  {
    name: 'Προθεσμία',
    selector: row => row.deadline,
    format: row => formatDateTime(row.deadline),
    sortable: true,
    width: '150px'
  },
]


// Row styles
const conditionalRowStyles = [
  {
    when: row => row.isopen,
    style: {
      color: 'green'
    }
  },
  {
    when: row => !row.isopen,
    style: {
      color: 'red'
    }
  }
]


export default function Index() {

  // ---------------------------------------------------------------
  // Local state and context
  // ---------------------------------------------------------------
  const [invitations, setInvitations] = React.useState([])
  const [showForm, setShowForm] = React.useState(false)
  const [user, setUser] = React.useState()
  const { loader: pageLoader, startLoader: startPageLoader, stopLoader: stopPageLoader } = useLoader()

  // ---------------------------------------------------------------
  // Fetch invitations and connected user on mount
  // ---------------------------------------------------------------
  React.useEffect(() => {

    startPageLoader()

    fetchConnectedUserApi()
      .then(response => {
        setUser(response)
      })
      .catch(error => console.log(error))

    fetchInvitationsApi()
      .then(response => {
        setInvitations(response)
        stopPageLoader()
      })
      .catch(error => {
        console.log(error)
        stopPageLoader()
      })

  }, [])

  // ---------------------------------------------------------------
  // New invitation handler
  // ---------------------------------------------------------------
  const handleNewInvitation = (values) => {
    setShowForm(false)
    postInvitationApi(values)
      .then(response => window.location.href = `/admin/invitations/${response.id}`)
      .catch(error => console.log(error))
  }


  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <>
      <Container className="mt-3 mb-5">
        {
          pageLoader ||
          <div className="card">
            <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4 className="card-title">
                Προσκλήσεις Εκδήλωσης Ενδιαφέροντος
              </h4>
              {
                (user?.is_admin || user?.is_editor) &&
                <Button size="sm" variant="success" onClick={() => setShowForm(true)}>Νέα Πρόσκληση</Button>
              }
            </div>
            <div className="card-body">
              <DataTable
                columns={columns}
                data={invitations}
                defaultSortFieldId={4}
                defaultSortAsc={false}
                pagination
                paginationComponentOptions={{
                  rowsPerPageText: 'Προσκλήσεις ανά σελίδα',
                  rangeSeparatorText: 'από',
                  selectAllRowsItem: true,
                  selectAllRowsItemText: 'Όλα',
                }}
                theme='default'
                noDataComponent=''//"Δεν υπάρχουν καταχωρημένες προσκλήσεις"
                conditionalRowStyles={conditionalRowStyles}
                highlightOnHover
                pointerOnHover
                onRowClicked={row => window.location.href = `/admin/invitations/${row.id}`}
              />
            </div>
          </div>
        }
      </Container>
      <InvitationModalForm show={showForm} onSubmit={handleNewInvitation} onCancel={() => setShowForm(false)} />
    </>
  )
}


// ---------------------------------------------------------------
// // Mount into dom
// ---------------------------------------------------------------
if (document.getElementById('admin-invitations-index')) {
  const root = ReactDOM.createRoot(document.getElementById('admin-invitations-index'))
  root.render(<Index />)
}
