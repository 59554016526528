import React from 'react'
import { useFormik } from 'formik'
import { Form, Modal, Button } from 'react-bootstrap'
import DatePicker, { registerLocale } from "react-datepicker"
import { formatFnsDbDate, parseDate } from '../../../../utils'
import el from 'date-fns/locale/el';

registerLocale('el', el)

const requiredFields = [
    'prot_aa', 'prot_date'
]

const fieldLengths = [
    {
        name: 'prot_aa',
        length: 100
    }
]

const $today = formatFnsDbDate(new Date())


export default function ProtokolloModalForm({ show, onSubmit, onCancel }) {

    // ---------------------------------------------------------------
    // Reset form
    // ---------------------------------------------------------------
    React.useEffect(() => {
        if (show) {
            formik.resetForm()
        }
    }, [show])

    // ---------------------------------------------------------------
    // Form validation
    // ---------------------------------------------------------------

    const validateForm = values => {

        const errors = {}

        // Check for required fields
        requiredFields.forEach(field => {
            if (!values[field]) {
                errors[field] = 'Υποχρεωτικό πεδίο'
            }
        })

        // Check for field lengths
        fieldLengths.forEach(field => {
            if (values[field.name] && values[field.name].length > field.length) {
                errors[field.name] = `Πληκτρολογείστε μέχρι ${field.length} χαρακτήρες`
            }
        })

        // Επιστροφή
        return errors
    }

    // ---------------------------------------------------------------
    // Initial form values
    // ---------------------------------------------------------------
    const initialValues = {
        id: -1,
        prot_aa: '',
        prot_date: $today    // set to today
    }

    // ---------------------------------------------------------------
    // Formik hook
    // ---------------------------------------------------------------
    const formik = useFormik({
        initialValues,
        onSubmit: onSubmit,
        validate: validateForm,
        enableReinitialize: true,
    })

    // ---------------------------------------------------------------
    // JSX
    // ---------------------------------------------------------------
    return (
        <Modal show={show} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>Πρωτοκόλληση Αίτησης</Modal.Title>
            </Modal.Header >
            <Modal.Body>
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <ProtAaField formik={formik} />
                    <ProtDateField formik={formik} />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={formik.handleSubmit}>Καταχώρηση</Button>
                <Button variant="danger" onClick={onCancel}>Ακύρωση</Button>
            </Modal.Footer>
        </Modal>
    )
}


// ********************************************************************************
// Field: pro_aa
// ********************************************************************************
function ProtAaField({ formik }) {
    return (
        <Form.Group className="mb-3" controlId="prot_aa">
            <Form.Label>ΑΡΙΘΜΟΣ ΠΡΩΤΟΚΟΛΛΟΥ (*)</Form.Label>
            <Form.Control
                size='sm'
                name="prot_aa"
                type="text"
                value={formik.values.prot_aa || ''}
                onChange={formik.handleChange}
                isInvalid={formik.touched.prot_aa && formik.errors.prot_aa}
            />
            <Form.Control.Feedback type="invalid">
                {formik.errors.prot_aa}
            </Form.Control.Feedback>
        </Form.Group>
    )
}


// ********************************************************************************
// Field: pro_date
// ********************************************************************************
function ProtDateField({ formik }) {
    return (
        <div className="form-group mb-3">
            <label htmlFor="prot_date" className="form-label">ΗΜΕΡΟΜΗΝΙΑ ΠΡΩΤΟΚΟΛΛΟΥ (*)</label>
            <DatePicker
                selected={formik.values.prot_date ? parseDate(formik.values.prot_date) : null}
                onChange={dt => formik.setFieldValue('prot_date', formatFnsDbDate(dt))}
                locale='el'
                dateFormat='dd/MM/yyyy'
                className='form-control'
                placeholderText='Eπιλέξτε ή πληκτρολογείστε ...'
            />
            {formik.touched.prot_date
                ?
                <Form.Control.Feedback type="invalid" style={{ display: 'block', width: '100%', marginTop: '0.25rem' }}>
                    {formik.errors.prot_date}
                </Form.Control.Feedback>
                : null
            }
        </div>
    )
}