import axios from "axios"

// Get connected user
export function fetchConnectedUserApi() {
  return new Promise((resolve, reject) => {
    axios.get('/api/auth/user')
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject("Σφάλμα αυθεντικοποίησης χρήστη")
      })
  })
}


// Register
export function registerApi(data) {
  return new Promise((resolve, reject) => {
    axios.post('/api/auth/register', data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        if (error.response.data.message) reject(error.response.data.message)
        else reject('Σφάλμα κατά την δημιουργία του λογαριασμού')
      })
  })
}

// Change password
export function changePasswordApi(data) {
  return new Promise((resolve, reject) => {
    axios.post('/api/auth/change-password', data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        if (error.response.data.message) reject(error.response.data.message)
        else reject('Σφάλμα κατά την αλλαγή του κωδικού πρόσβασης')
      })
  })
}

// Logout

