import axios from "axios"

// Έλεγχος αν υπάρχει αίτηση για συγκερκιμένη θέση
// από τον συνδεδεμένο χρήστη
export function checkApplicationExistsApi(position) {
    return new Promise((resolve, reject) => {
        axios.get(`/api/applications/check/${position.id}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject("Σφάλμα κατά τον έλεγχο υπάρξης αίτησης")
            })
    })
}

// Fetch connected user's applications
export function fetchUserApplicationsApi() {
    return new Promise((resolve, reject) => {
        axios.get(`/api/applications`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject("Σφάλμα κατά την ανάκτηση των αιτήσεων")
            })
    })
}

// Fetch single application
export function fetchApplicationApi(applicationId) {
    return new Promise((resolve, reject) => {
        axios.get(`/api/applications/${applicationId}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject("Σφάλμα κατά την ανάκτηση της αίτησης")
            })
    })
}

// Update personal data
export function updatePersonalDataApi(personal) {
    return new Promise((resolve, reject) => {
        axios.put(`/api/applications/personals/${personal.application_id}`, personal)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject("Σφάλμα κατά την αποθήκευση των προσωπικών στοιχείων")
            })
    })
}

// Update contact data
export function updateContactDataApi(contact) {
    return new Promise((resolve, reject) => {
        axios.put(`/api/applications/contacts/${contact.application_id}`, contact)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject("Σφάλμα κατά την αποθήκευση των στοιχείων στοιχείων επικοινωνίας")
            })
    })
}

// Upload application document
export function postApplicationDocumentApi(document, file) {
    var formData = new FormData()
    formData.append('filename', file)
    return new Promise((resolve, reject) => {
        axios.post(`/api/application-documents/${document.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject("Σφάλμα κατά την αποθήκευση του εγγράφου")
            })
    })
}

// Upload application file
export function postApplicationFileApi(fields, file) {
    var formData = new FormData()
    formData.append('application_id', fields.application_id)
    formData.append('descr', fields.descr)
    formData.append('filename', file)
    return new Promise((resolve, reject) => {
        axios.post('/api/application-files', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject("Σφάλμα κατά την αποθήκευση του αρχείου της αίτησης")
            })
    })
}

// delete application document
export function deleteApplicationDocumentApi(document) {
    return new Promise((resolve, reject) => {
        axios.delete(`/api/application-documents/${document.id}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject("Σφάλμα κατά την διαγραφή του αρχείου της αίτησης")
            })
    })
}

// delete application file
export function deleteApplicationFileApi(applicationFile) {
    return new Promise((resolve, reject) => {
        axios.delete(`/api/application-files/${applicationFile.id}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject("Σφάλμα κατά την διαγραφή του αρχείου της αίτησης")
            })
    })
}

// Οριστικοποίηση αίτησης
export function submitApplicationApi(application) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/applications/${application.id}/submit`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject("Σφάλμα κατά την οριστικοποίηση της αίτησης")
            })
    })
}

// Πρωτοκόλληση αίτησης
// data: {application_id, pro_aa, prot_date}
export function setProtokolloApi(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/admin/applications/${data.application_id}/protokollo`, data)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject("Σφάλμα κατά την πρωτοκόλληση της αίτησης")
            })
    })
}