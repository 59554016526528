import React from 'react'
import { formatDate, formatDateTime } from '../utils'

const styles = {
    hr: {
        height: 1,
        color: 'black',
        backgroundColor: 'black'
    },
    subtitle: {
        display: 'flex',
        gap: 20,
        flexWrap: 'wrap'
    }
}

/*
props:
-   invitation
*/
export default function InvitationHeader({ invitation }) {
    return (
        <>
            <h4>{invitation?.descr}</h4>
            <hr style={styles.hr} />
            <div style={styles.subtitle}>
                <p>Δημοσίευση: <b>{formatDate(invitation?.prot_date)}</b></p>
                <p>Αριθμός πρωτοκόλλου: <b>{invitation?.prot_aa}</b></p>
                <p>Προθεσμία υποβολής προτάσεων: <b>{formatDateTime(invitation?.deadline)}</b></p>
            </div>
        </>
    )
}
