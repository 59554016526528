import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { ApplicationEditContext } from '../../../pages/applications/edit'
import ContactData from './ContactTab'
import DocumentsTab from './DocumentsTab'
import FilesTab from './FilesTab'
import PersonalData from './PersonalTab'
import PreviewTab from './SubmitTab'

// FormTabs context
export const FormTabsContext = React.createContext()

export default function FormTabs() {

    // ---------------------------------------------------------------
    // local state and context
    // ---------------------------------------------------------------
    const [application, setApplication] = React.useContext(ApplicationEditContext)
    const [tabIndex, setTabIndex] = React.useState(0)
    const [tab0Disabled, setTab0Disabled] = React.useState(false)
    const [tab1Disabled, setTab1Disabled] = React.useState(true)
    const [tab2Disabled, setTab2Disabled] = React.useState(true)
    const [tab3Disabled, setTab3Disabled] = React.useState(true)
    const [tab4Disabled, setTab4Disabled] = React.useState(true)

    // ---------------------------------------------------------------
    // Go to specified tab
    // Set this enabled and all other disabled
    // ---------------------------------------------------------------
    const setActiveTab = index => {
        if (index == 0) {
            setTab0Disabled(false)
            setTab1Disabled(true)
            setTab2Disabled(true)
            setTab3Disabled(true)
            setTab4Disabled(true)
            setTabIndex(0)
        }
        else if (index == 1) {
            setTab0Disabled(true)
            setTab1Disabled(false)
            setTab2Disabled(true)
            setTab3Disabled(true)
            setTab4Disabled(true)
            setTabIndex(1)
        }
        else if (index == 2) {
            setTab0Disabled(true)
            setTab1Disabled(true)
            setTab2Disabled(false)
            setTab3Disabled(true)
            setTab4Disabled(true)
            setTabIndex(2)
        }
        else if (index == 3) {
            setTab0Disabled(true)
            setTab1Disabled(true)
            setTab2Disabled(true)
            setTab3Disabled(false)
            setTab4Disabled(true)
            setTabIndex(3)
        }
        else if (index == 4) {
            setTab0Disabled(true)
            setTab1Disabled(true)
            setTab2Disabled(true)
            setTab3Disabled(true)
            setTab4Disabled(false)
            setTabIndex(4)
        }
    }

    // ---------------------------------------------------------------
    // JSX
    // ---------------------------------------------------------------
    return (
        <FormTabsContext.Provider value={{ setActiveTab }}>
            <Tabs className="mt-4" selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                <TabList>
                    <Tab disabled={tab0Disabled}>1. Προσωπικά Στοιχεία</Tab>
                    <Tab disabled={tab1Disabled}>2. Στοιχεία Επικοινωνίας</Tab>
                    <Tab disabled={tab2Disabled}>3. Δικαιολογητικά</Tab>
                    <Tab disabled={tab3Disabled}>4. Λοιπά Αρχεία</Tab>
                    <Tab disabled={tab4Disabled}>5. Οριστικοποίηση</Tab>
                </TabList>

                <TabPanel>
                    <PersonalData />
                </TabPanel>
                <TabPanel>
                    <ContactData />
                </TabPanel>
                <TabPanel>
                    <DocumentsTab />
                </TabPanel>
                <TabPanel>
                    <FilesTab />
                </TabPanel>
                <TabPanel>
                    <PreviewTab />
                </TabPanel>
            </Tabs>
        </FormTabsContext.Provider>
    )
}

