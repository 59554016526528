import React from 'react'
import { useFormik } from 'formik'
import { Form, Modal, Button } from 'react-bootstrap'

// Invitation document modal
export default function DocumentModalForm({ show, document, onSubmit, onCancel }) {

  // ---------------------------------------------------------------
  // Reset form
  // ---------------------------------------------------------------
  React.useEffect(() => {
    if (!show) {
      formik.resetForm() // reset on close
    }
  }, [show])

  // ---------------------------------------------------------------
  // Form validation
  // ---------------------------------------------------------------
  const requiredFields = [
    'descr', 'required'
  ]

  const validateForm = values => {

    const errors = {}

    // Check for required fields
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = 'Υποχρεωτικό πεδίο'
      }
    })

    // Επιστροφή
    return errors
  }

  // ---------------------------------------------------------------
  // Initial form values
  // ---------------------------------------------------------------
  const initialValues = {
    id: document?.id || -1,
    descr: document?.descr || '',
    required: !document ? 1 : document?.required == true ? 1 : 0
  }

  // ---------------------------------------------------------------
  // Formik hook
  // ---------------------------------------------------------------
  const formik = useFormik({
    initialValues,
    onSubmit: onSubmit,
    validate: validateForm,
    enableReinitialize: true,
  })

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <Modal show={show} backdrop="static" keyboard={false} size="lg">
      <Modal.Header>
        <Modal.Title>{document ? "Επεξεργασία Δικαιολογητικού" : "Νέο Δικαιολογητικό"}</Modal.Title>
      </Modal.Header >
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>

          {/* descr */}
          <Form.Group className="mb-3" controlId="descr">
            <Form.Label>ΠΕΡΙΓΡΑΦΗ ΔΙΚΑΙΟΛΟΓΗΤΙΚΟΥ (*)</Form.Label>
            <Form.Control
              name="descr"
              type="textarea"
              rows={4}
              value={formik.values.descr || ''}
              onChange={formik.handleChange}
              isInvalid={formik.touched.descr && formik.errors.descr}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.descr}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Required */}
          <Form.Group className="mb-3" controlId="required">
            <Form.Label>ΑΠΑΙΤΕΙΤΑΙ (*)</Form.Label>
            <Form.Control
              as="select"
              value={formik.values.required}
              onChange={e => formik.setFieldValue('required', e.target.value)}
              onBlur={() => formik.setFieldTouched('required')}
              isInvalid={formik.touched.required && formik.errors.required}
            >
              <option value={1}>ΝΑΙ</option>
              <option value={0}>ΟΧΙ</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {formik.errors.required}
            </Form.Control.Feedback>
          </Form.Group>

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={formik.handleSubmit}>Καταχώρηση</Button>
        <Button variant="danger" onClick={onCancel}>Ακύρωση</Button>
      </Modal.Footer>
    </Modal>
  )
}
