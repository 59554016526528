// /invitations/{invitation_id}
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Container } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { fetchInvitationApi } from '../../api/invitations'
import { fetchConnectedUserApi } from '../../api/auth'
import InvitationFiles from '../../components/InvitationFiles'
import InvitationTypeOne from '../../components/TypeOne/Invitation/InvitationTypeOne'
import InvitationHeader from '../../components/InvitationHeader'
import useLoader from '../../hooks/useLoader'

export default function InvitationShow() {

  // ---------------------------------------------------------------
  // Local state and context
  // ---------------------------------------------------------------
  const [invitation, setInvitation] = React.useState()
  const [user, setUser] = React.useState()
  const { loader: pageLoader, startLoader: startPageLoader, stopLoader: stopPageLoader } = useLoader()

  // ---------------------------------------------------------------
  // Get invitation id from url and fetch invitation
  // ---------------------------------------------------------------
  React.useEffect(() => {

    startPageLoader()

    const url = window.location.href.split('/')
    const invitationsId = url[url.length - 1]

    // Fetch Invitation
    fetchInvitationApi(invitationsId)
      .then(data => {
        setInvitation(data)
        stopPageLoader()
      })
      .catch(error => {
        toast.error(error)
        stopPageLoader()
      })

    // Fetch connected user
    fetchConnectedUserApi()
      .then(data => setUser(data))
      .catch(error => toast.error(error))
  }, [])

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <Container className="mt-3 mb-5">
      {
        pageLoader ||
        <div>
          <ToastContainer position={toast.POSITION.TOP_CENTER} theme="colored" />
          <InvitationHeader invitation={invitation} />
          <InvitationFiles files={invitation?.files} />

          {/* Render invitaion positions based on type */}
          {
            invitation?.type == 1 && <InvitationTypeOne positions={invitation?.positions} />
          }
        </div>}
    </Container>

  )
}


// ---------------------------------------------------------------
// // Mount into dom
// ---------------------------------------------------------------
if (document.getElementById('invitations-show')) {
  const root = ReactDOM.createRoot(document.getElementById('invitations-show'))
  root.render(<InvitationShow />)
}