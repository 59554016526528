import React from 'react'
import { Button } from 'react-bootstrap'

export default function ApplicationActions({ application, onProtokollo }) {

  // Αν έχει ήδη πρωτοκολληθεί επιστροφή null
  if (application.prot_aa) return null

  // Αν ο τρέχον χρήστης δεν έχει δικαίωμα πρωτοκόλλησης, επιστροφή null
  if (!application.can_protokollo) return null

  return (
    <div>
      <Button
        variant='outline-success'
        size='sm'
        title="Πρωτοκόλληση"
        onClick={() => onProtokollo(application)}
        disabled={application.status === 0}
      >
        Πρωτοκόλληση
      </Button>
    </div>
  )
}
