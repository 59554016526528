import React from 'react'
import { Button } from 'react-bootstrap'
import { createPositionApi, deletePositionApi, updatePositionApi } from '../../../../api/invitations'
import { fetchSxolesApi } from '../../../../api/sxoles'
import { fetchTmimataApi } from '../../../../api/tmimata'
import { getConfirm } from '../../../../components/Confirm'
import PositionModalForm from './PositionModalForm'
import AdminPositionsTypeOne from '../../../../components/TypeOne/Invitation/AdminPositionsTypeOne'

export const PositionsContext = React.createContext()

export default function Positions({ invitation, setInvitation }) {

    // ---------------------------------------------------------------
    // Local state and context
    // ---------------------------------------------------------------
    const [selectedPosition, setSelectedPosition] = React.useState()
    const [showForm, setShowForm] = React.useState(false)
    const [sxoles, setSxoles] = React.useState([])
    const [tmimata, setTmimata] = React.useState([])

    // ---------------------------------------------------------------
    // fetch sxoles and tmimata on mount
    // ---------------------------------------------------------------
    React.useEffect(() => {
        fetchSxolesApi()
            .then(response => setSxoles(response))
            .catch(error => console.log(error))

        fetchTmimataApi()
            .then(response => setTmimata(response))
            .catch(error => console.log(error))
    }, [])

    // ---------------------------------------------------------------
    // handle new position click
    // ---------------------------------------------------------------
    const handleNewPositionClick = () => {
        setSelectedPosition(null)
        setShowForm(true)
    }

    // ---------------------------------------------------------------
    // handle edit position click
    // ---------------------------------------------------------------
    const handleEditPositionClick = (position) => {
        setSelectedPosition(position)
        setShowForm(true)
    }

    // ---------------------------------------------------------------
    // Submit position handler (create or update)
    // ---------------------------------------------------------------
    const handleSubmitPosition = (position) => {
        position.invitation_id = invitation.id

        if (position.id == -1) {  // new
            createPositionApi(position)
                .then(response => {
                    setInvitation(response)
                    setShowForm(false)
                })
                .catch(error => {
                    console.log(error)
                    setShowForm(false)
                })
        }
        else {  // update
            updatePositionApi(position)
                .then(response => {
                    setInvitation(response)
                    setShowForm(false)
                })
                .catch(error => {
                    console.log(error)
                    setShowForm(false)
                })
        }
    }

    // ---------------------------------------------------------------
    // Delete position handler
    // ---------------------------------------------------------------
    const handleDeletePosition = (position) => {
        getConfirm(
            'Διαγραφή θέσης',
            'Να διαγραφεί η επιλεγμένη θέση;',
            "Διαγραφή",
            'Ακύρωση',
        ).then(response => {
            if (response) {
                deletePositionApi(position)
                    .then(response => setInvitation(response))
                    .catch(error => console.log(error))
            }
        })
    }


    // ---------------------------------------------------------------
    // JSX
    // ---------------------------------------------------------------
    return (
        <PositionsContext.Provider value={{ handleEditPositionClick, handleDeletePosition }}>
            <div className="card mt-4">
                <div className="card-header" style={{ display: "flex", justifyContent: 'space-between' }}>
                    <h5 className="card-title">Προκηρυσσόμενες Θέσεις</h5>
                    <Button size='sm' variant='success' onClick={handleNewPositionClick}>
                        Νέα Θέση
                    </Button>
                </div>
                <div className="card-body">
                    {
                        invitation?.positions?.length > 0
                            ?
                            invitation?.type == 1 && <AdminPositionsTypeOne positions={invitation?.positions} />
                            // TODO: Add for other invitation types
                            :
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div>Δεν έχουν καταχωρηθεί θέσεις στην πρόσκληση</div>
                            </div>
                    }
                </div>
            </div>
            <PositionModalForm
                show={showForm}
                position={selectedPosition}
                invitationType={invitation?.type}
                sxoles={sxoles}
                tmimata={tmimata}
                onSubmit={handleSubmitPosition}
                onCancel={() => setShowForm(false)}
            />
        </PositionsContext.Provider>
    )
}
