import React from 'react'
import { Button, Table } from 'react-bootstrap'
import { FaTrashAlt } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import { createTmimaApi, deleteTmimaApi, updateTmimaApi } from '../../../../api/tmimata'
import { getConfirm } from '../../../../components/Confirm'
import TmimaModalForm from './TmimaModalForm'

export default function Tmimata({ sxoli, setSxoli }) {

    // ---------------------------------------------------------------
    // Local state and context
    // ---------------------------------------------------------------
    const [showForm, setShowForm] = React.useState(false)
    const [selectedTmima, setSelectedTmima] = React.useState()

    // ---------------------------------------------------------------
    // handle new tmima click
    // ---------------------------------------------------------------
    const handleNewTmimaClick = () => {
        setSelectedTmima(null)
        setShowForm(true)
    }

    // ---------------------------------------------------------------
    // handle edit tmima click
    // ---------------------------------------------------------------
    const handleEditTmimaClick = (tmima) => {
        setSelectedTmima(tmima)
        setShowForm(true)
    }

    // ---------------------------------------------------------------
    // handle delete tmima click
    // ---------------------------------------------------------------
    const handleDeleteTmimaClick = (tmima) => {
        getConfirm(
            'Επιβεβαίωση διαγραφής τμήματος',
            `Να διαγραφεί το τμήμα "${tmima.descr}"?`,
            "Διαγραφή",
            'Ακύρωση',
        ).then(response => {
            if (response) {
                deleteTmimaApi(tmima)
                    .then(response => setSxoli(response))
                    .catch(error => console.log(error))
            }
        })
    }

    // ---------------------------------------------------------------
    // handle submit tmima (new or edit)
    // ---------------------------------------------------------------
    const handleSubmitTmima = (values) => {
        const tmima = {
            id: values.id,
            sxoli_id: sxoli.id,
            descr: values.descr,
            city: values.city
        }

        if (values.id == -1) {
            createTmimaApi(tmima)
                .then(response => setSxoli(response))
                .catch(error => console.log(error))
        }
        else {
            updateTmimaApi(tmima)
                .then(response => setSxoli(response))
                .catch(error => console.log(error))
        }

        setShowForm(false)
    }

    // ---------------------------------------------------------------
    // JSX
    // ---------------------------------------------------------------
    return (
        <div className="card mt-4">
            <div className="card-header" style={{ display: "flex", justifyContent: 'space-between' }}>
                <h5 className="card-title">Τμήματα</h5>
                <Button size='sm' variant='success' onClick={handleNewTmimaClick}>
                    Προσθήκη Τμήματος
                </Button>
            </div>
            <div className="card-body">
                {
                    sxoli?.tmimata.length == 0
                        ?
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div>Δεν έχουν καταχωρηθεί τμήματα στη σχολή</div>
                        </div>
                        :
                        <Table striped hover className='mt-2'>
                            <thead>
                                <tr>
                                    <th>Περιγραφή Τμήματος</th>
                                    <th>Πόλη</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sxoli?.tmimata.map(tmima =>
                                        <tr key={tmima.id}>
                                            <td>{tmima.descr}</td>
                                            <td>{tmima.city}</td>
                                            <td style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                {
                                                    tmima.can_delete &&
                                                    <Button
                                                        variant='outline-danger'
                                                        size='sm'
                                                        title="Διαγραφή Τμήματος"
                                                        onClick={() => { handleDeleteTmimaClick(tmima) }}
                                                    >
                                                        <FaTrashAlt />
                                                    </Button>
                                                }
                                                <Button
                                                    variant='outline-primary'
                                                    size='sm'
                                                    title="Επεξεργασία Τμήματος"
                                                    onClick={() => { handleEditTmimaClick(tmima) }}
                                                    style={{ marginLeft: '5px' }}
                                                >
                                                    <MdEdit />
                                                </Button>

                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                }
            </div>
            <TmimaModalForm
                show={showForm}
                tmima={selectedTmima}
                onSubmit={handleSubmitTmima}
                onCancel={() => setShowForm(false)}
            />
        </div>
    )
}
