import React from 'react'
import ReactDOM from 'react-dom/client'
import { Container, Button } from 'react-bootstrap'
import { createSxoliApi, fetchSxolesApi } from '../../../../api/sxoles'
import DataTable from 'react-data-table-component'
import TextModalForm from '../../../../components/TextModalForm'
import useLoader from '../../../../hooks/useLoader'

// Datatabble columns
const columns = [
  {
    name: 'Περιγραφή Σχολής',
    selector: row => row.descr,
    sortable: true
  },
  {
    name: 'Αριθμός Τμημάτων',
    selector: row => row.tmimata_num,
    sortable: true,
    center: true
  }
]

export default function Index() {

  // ---------------------------------------------------------------
  // Local state and context
  // ---------------------------------------------------------------
  const [sxoles, setSxoles] = React.useState([])
  const [showSxoliForm, setShowSxoliForm] = React.useState(false)
  const { loader: pageLoader, startLoader: startPageLoader, stopLoader: stopPageLoader } = useLoader()

  // ---------------------------------------------------------------
  // Fetch sxoles on mount
  // ---------------------------------------------------------------
  React.useEffect(() => {
    startPageLoader()

    fetchSxolesApi()
      .then(data => {
        setSxoles(data)
        stopPageLoader()
      })
      .catch(error => {
        console.log(error)
        stopPageLoader()
      })
  }, [])

  // ---------------------------------------------------------------
  // handle sxoli create (descr)
  // ---------------------------------------------------------------
  const handleSxoliCreate = data => {
    const newSxoli = {
      descr: data.field
    }
    createSxoliApi(newSxoli)
      .then(response => window.location.href = `/admin/sxoles/${response.id}`)
      .catch(error => console.log(error))

    setShowSxoliForm(false)
  }

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <Container className="mt-3 mb-5">
      {
        pageLoader ||

        <div className="card">
          <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h4 className="card-title">
              Σχολές
            </h4>
            <Button size="sm" variant="success" onClick={() => setShowSxoliForm(true)}>Νέα Σχολή</Button>
          </div>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={sxoles}
              defaultSortFieldId={1}
              defaultSortAsc={true}
              pagination
              paginationComponentOptions={{
                rowsPerPageText: 'Σχολές ανά σελίδα',
                rangeSeparatorText: 'από',
                selectAllRowsItem: true,
                selectAllRowsItemText: 'Όλα',
              }}
              theme='default'
              noDataComponent="Δεν υπάρχουν καταχωρημένες σχολές"
              highlightOnHover
              pointerOnHover
              onRowClicked={row => window.location.href = `/admin/sxoles/${row.id}`}
            />
          </div>
          <TextModalForm
            show={showSxoliForm}
            title="Νέα Σχολή"
            label="Περιγραφή Σχολής"
            data=''
            required={true}
            maxLength={255}
            onSubmit={handleSxoliCreate}
            onCancel={() => setShowSxoliForm(false)}
          />
        </div>
      }
    </Container>
  )
}



// ---------------------------------------------------------------
// Mount into dom
// ---------------------------------------------------------------
if (document.getElementById('admin-sxoles-index')) {
  const root = ReactDOM.createRoot(document.getElementById('admin-sxoles-index'))
  root.render(<Index />)
}
