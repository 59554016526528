import React from 'react'
import { Button, Table } from 'react-bootstrap'
import { FaTrashAlt } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import { createInvitationDocumentApi, deleteInvitationDocumentApi, updateInvitationDocumentApi } from '../../../../api/invitations'
import { getConfirm } from '../../../../components/Confirm'
import DocumentModalForm from './DocumentModalForm'

export default function Documents({ invitation, setInvitation }) {

    // ---------------------------------------------------------------
    // Local state and context
    // ---------------------------------------------------------------
    const [showForm, setShowForm] = React.useState(false)
    const [selectedDocument, setSelectedDocument] = React.useState()

    // ---------------------------------------------------------------
    // handle new document click
    // ---------------------------------------------------------------
    const handleNewDocumentClick = () => {
        setSelectedDocument(null)
        setShowForm(true)
    }

    // ---------------------------------------------------------------
    // handle edit document click
    // ---------------------------------------------------------------
    const handleEditDocumentClick = (document) => {
        setSelectedDocument(document)
        setShowForm(true)
    }

    // ---------------------------------------------------------------
    // handle delete document click
    // ---------------------------------------------------------------
    const handleDeleteDocumentClick = (document) => {
        getConfirm(
            'Επιβεβαίωση διαγραφής δικαιολογητικού',
            `Να διαγραφεί το δικαιολογητικό "${document.descr}"?`,
            "Διαγραφή",
            'Ακύρωση',
        ).then(response => {
            if (response) {
                deleteInvitationDocumentApi(document)
                    .then(response => setInvitation(response))
                    .catch(error => console.log(error))
            }
        })
    }

    // ---------------------------------------------------------------
    // handle submit document (new or edit)
    // ---------------------------------------------------------------
    const handleSubmitDocument = (values) => {
        const document = {
            id: values.id,
            invitation_id: invitation.id,
            descr: values.descr,
            required: values.required
        }

        if (values.id == -1) {
            createInvitationDocumentApi(document)
                .then(response => {
                    setInvitation(response)
                })
                .catch(error => console.log(error))
        }
        else {
            updateInvitationDocumentApi(document)
                .then(response => setInvitation(response))
                .catch(error => console.log(error))

        }

        setShowForm(false)
    }


    // ---------------------------------------------------------------
    // JSX
    // ---------------------------------------------------------------
    return (
        <div className="card mt-4">
            <div className="card-header" style={{ display: "flex", justifyContent: 'space-between' }}>
                <h5 className="card-title">Δικαιολογητικά</h5>
                <Button size='sm' variant='success' onClick={handleNewDocumentClick}>
                    Νέο Δικαιολογητικό
                </Button>
            </div>
            <div className="card-body">
                {
                    invitation?.documents.length == 0
                        ?
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div>Δεν έχουν καταχωρηθεί δικαιολογητικά</div>
                        </div>
                        :
                        <Table striped hover className='mt-2'>
                            <thead>
                                <tr>
                                    <th>Περιγραφή Δικαιολογητικού</th>
                                    <th style={{ textAlign: 'center' }}>Απαιτείται</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    invitation?.documents.map(document =>
                                        <tr key={document.id}>
                                            <td>{document.descr}</td>
                                            <td style={{ textAlign: 'center' }}>{document.required == 1 ? "NAI" : "OXI"}</td>
                                            <td style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button
                                                    variant='outline-primary'
                                                    size='sm'
                                                    title="Επεξεργασία Δικαιολογητικού"
                                                    onClick={() => { handleEditDocumentClick(document) }}
                                                    style={{ marginRight: '5px' }}
                                                >
                                                    <MdEdit />
                                                </Button>
                                                {
                                                    document.can_be_deleted &&
                                                    <Button
                                                        variant='outline-danger'
                                                        size='sm'
                                                        title="Διαγραφή Δικαιολογητικού"
                                                        onClick={() => { handleDeleteDocumentClick(document) }}
                                                    >
                                                        <FaTrashAlt />
                                                    </Button>
                                                }
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                }
            </div>
            <DocumentModalForm
                show={showForm}
                document={selectedDocument}
                onSubmit={handleSubmitDocument}
                onCancel={() => setShowForm(false)}
            />
        </div>
    )
}