import { parseISO, format, getMonth, formatISO } from 'date-fns'
import { el } from 'date-fns/locale'

// Γενικές Μηνών
const genikiOfMonth = [
  'Ιανουαρίου',
  'Φεβρουαρίου',
  'Μαρτίου',
  'Απριλίου',
  'Μαϊου',
  'Ιουνίου',
  'Ιουλίου',
  'Αυγούστου',
  'Σεπτεμβρίου',
  'Οκτωβρίου',
  'Νοεμβρίου',
  'Δεκεμβρίου'
]

// -------------------------------------------------------------
// Sort strings alpha-numerically
// (treat numbers correctly)
// -------------------------------------------------------------
export function sortAlphaNum(a, b) {

  var reA = /[^a-zA-Z]/g;
  var reN = /[^0-9]/g;

  var aA = a.replace(reA, "");
  var bA = b.replace(reA, "");

  if (aA === bA) {
    var aN = parseInt(a.replace(reN, ""), 10);
    var bN = parseInt(b.replace(reN, ""), 10);
    return aN === bN ? 0 : aN > bN ? 1 : -1;
  } else {
    return aA > bA ? 1 : -1;
  }

}

// Parse iso formated date into Date()
export function parseDate(isoDate) {
  if (!isoDate) return null
  var d = parseISO(isoDate)
  if (d == 'Invalid Date') return null
  else return d
}

// Format date to dd/mm/yyyy
// Should be in iso format
export function formatDate(isoDate) {
  if (!isoDate) return '';
  return format(parseISO(isoDate), 'dd/MM/yyyy');
}

// Format iso date into HH:Mm
export function formatTime(isoDate) {
  return format(parseISO(isoDate, { locale: el }), 'HH:mm');
}

// Format iso datetime into dd/mm/yyyy, HH:Mm
export function formatDateTime(isoDate) {
  if (!isoDate) return ''
  return format(parseISO(isoDate, { locale: el }), 'dd/MM/yyyy, HH:mm');
}

// Format date-fns date into dd/mm/yyyy
export function formatFnsDate(fnsDate) {
  return format(fnsDate, 'dd/MM/yyyy')
}

// Format date-fns date into yyyy-mm-dd
// for database store
export function formatFnsDbDate(fnsDate) {
  if (!fnsDate) return null
  return format(fnsDate, 'yyyy-MM-dd', { locale: el })
}

// Format date-fns date into yyyy-mm-dd HH:mm
// for database store
export function formatFnsDbDatetime(fnsDate) {
  if (!fnsDate) return null
  return format(fnsDate, 'yyyy-MM-dd HH:mm', { locale: el })
}

// Format date-fns date into Δευτέρα, 6 Απριλίου 2022
export function formatFnsFullDate(fnsDate) {
  const month = getMonth(fnsDate)
  return format(fnsDate, `eeee, d ${genikiOfMonth[month]} yyyy`, { locale: el })
}

export function isoDate(fnsDate) {
  return formatISO(fnsDate)
}

// Καταμέτρηση απαιτούμενων/υποβληθέντων δικαιολογητικών
export function countAppDocs(documents) {

  var required = 0
  var submitted = 0

  documents?.forEach(doc => {
    if (doc.document.required) {
      required += 1
      if (doc.url) submitted += 1
    }
  })

  return {
    required, submitted
  }
}

// Dates should be to ISO format
export function sortDates(dateA, dateB) {

  if (!dateA) return 1
  if (!dateB) return -1

  const dtA = parseDate(dateA)
  const dtB = parseDate(dateB)


  if (dtA > dtB) {
    return 1
  }

  if (dtA < dtB) {
    return -1
  }

  return 0
}