import axios from "axios"

// Fetch all
export function fetchRolesApi() {
    return new Promise((resolve, reject) => {
        axios.get(`/api/roles`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject("Σφάλμα κατά την ανάκτηση των ρόλων")
            })
    })
}