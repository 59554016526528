import React from 'react'
import { Button } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { createMemberApi, deleteMemberApi, updateMemberApi } from '../../../../api/members'
import { fetchRolesApi } from '../../../../api/roles'
import { fetchSxolesApi } from '../../../../api/sxoles'
import { fetchTmimataApi } from '../../../../api/tmimata'
import MemberModalForm from './MemberModalForm'
import { MdEdit } from 'react-icons/md'
import { FaTrashAlt } from 'react-icons/fa'
import { getConfirm } from '../../../../components/Confirm'
import { sendMailToMembersApi } from '../../../../api/invitations'
import { toast } from 'react-toastify'


function Members({ invitation, setInvitation }) {

    // Datatable columns
    const columns = [
        {
            name: 'Ονοματεπώνυμο',
            selector: row => row.user.fullname,
            sortable: true,
            wrap: true,
        },
        {
            name: 'E-mail',
            selector: row => row.user.email,
            sortable: true,
        },
        {
            name: 'Τμήμα',
            selector: row => `${row.tmima?.descr} (${row.tmima?.city})`,
            format: row => row.tmima ? `${row.tmima?.descr} (${row.tmima?.city})` : '(ΟΛΑ)',
            sortable: true,
            wrap: true,
            grow: 2,
        },
        {
            name: 'Ρόλος',
            selector: row => row.role.descr,
            sortable: true,
            wrap: true.valueOf,
        },
        {
            name: '',
            cell: row => <Actions member={row} onEdit={handleEditMemberClick} onDelete={handleDeleteMemberClick} />,
            sortable: false,
            right: true,
            width: 100
        },
    ]

    // ---------------------------------------------------------------
    // Local state and context
    // ---------------------------------------------------------------
    const [showForm, setShowForm] = React.useState(false)
    const [selectedMember, setSelectedMember] = React.useState()
    const [sxoles, setSxoles] = React.useState([])
    const [tmimata, setTmimata] = React.useState([])
    const [roles, setRoles] = React.useState([])

    // ---------------------------------------------------------------
    // fetch sxoles, tmimata and roles on mount
    // ---------------------------------------------------------------
    // ---------------------------------------------------------------
    // fetch sxoles and tmimata on mount
    // ---------------------------------------------------------------
    React.useEffect(() => {
        fetchSxolesApi()
            .then(response => setSxoles(response))
            .catch(error => console.log(error))

        fetchTmimataApi()
            .then(response => setTmimata(response))
            .catch(error => console.log(error))

        fetchRolesApi()
            .then(response => setRoles(response))
            .catch(error => console.log(error))
    }, [])


    // ---------------------------------------------------------------
    // handle new member click
    // ---------------------------------------------------------------
    const handleNewMemberClick = () => {
        setSelectedMember(null)
        setShowForm(true)
    }

    // ---------------------------------------------------------------
    // handle edit user click
    // ---------------------------------------------------------------
    const handleEditMemberClick = (member) => {
        setSelectedMember(member)
        setShowForm(true)
    }

    // ---------------------------------------------------------------
    // handle send mail to members 
    // ---------------------------------------------------------------
    const handleSendMailToMembers = () => {
        getConfirm(
            'Επιβεβαίωση αποστολής',
            'Με την επιλογή αυτή θα αποσταλεί σε όλα τα μέλη της πρόσκλησης ένα email με τις υποβληθείσες αιτήσεις που επιβλέπουν. Να γίνει η αποστολή?',
            "Επιβεβαίωση",
            'Ακύρωση',
        ).then(response => {
            if (response) {
                sendMailToMembersApi(invitation)
                    .then(response => toast.success(response.message))
                    .catch(error => toast.error(error))
            }
        })
    }

    // ---------------------------------------------------------------
    // handle delete member click
    // ---------------------------------------------------------------
    const handleDeleteMemberClick = (member) => {
        getConfirm(
            'Επιβεβαίωση διαγραφής μέλους',
            `Να διαγραφεί το μέλος "${member.user.fullname}" ? `,
            "Διαγραφή",
            'Ακύρωση',
        ).then(response => {
            if (response) {
                deleteMemberApi(member)
                    .then(response => setInvitation(response))
                    .catch(error => console.log(error))
            }
        })
    }

    // ---------------------------------------------------------------
    // handle submit document (new or edit)
    // ---------------------------------------------------------------
    const handleSubmitMember = (values) => {

        const member = {
            id: values.id,
            invitation_id: invitation.id,
            email: values.email,
            surname: values.surname,
            firstname: values.firstname,
            role_id: values.role_id,
            tmima_id: values.tmima_id,
        }

        if (values.id == -1) {
            createMemberApi(member)
                .then(response => {
                    setInvitation(response)
                })
                .catch(error => console.log(error))
        }
        else {
            updateMemberApi(member)
                .then(response => setInvitation(response))
                .catch(error => console.log(error))
        }

        setShowForm(false)
    }


    // ---------------------------------------------------------------
    // JSX
    // ---------------------------------------------------------------
    return (
        <div className="card mt-4">
            <div className="card-header" style={{ display: "flex", justifyContent: 'space-between' }}>
                <h5 className="card-title">Διαχειριστές - Μέλη</h5>
                <Button size='sm' variant='success' onClick={handleNewMemberClick}>
                    Προσθήκη Μέλους
                </Button>
            </div>
            <div className="card-body">
                <DataTable
                    columns={columns}
                    data={invitation?.members}
                    defaultSortFieldId={1}
                    defaultSortAsc={true}
                    striped={true}
                    pagination
                    paginationComponentOptions={{
                        rowsPerPageText: 'Μέλη ανά σελίδα',
                        rangeSeparatorText: 'από',
                        selectAllRowsItem: true,
                        selectAllRowsItemText: 'Όλα',
                    }}
                    theme='default'
                    noDataComponent="Δεν υπάρχουν καταχωρημένα μέλη στην πρόσκληση"
                    highlightOnHover
                />
            </div>
            <div className="card-footer" style={{ display: 'flex', gap: '15px' }}>
                <Button variant='primary' onClick={handleSendMailToMembers}>
                    Συγκεντρωτική Ενημέρωση Μελών
                </Button>
            </div>
            <MemberModalForm
                show={showForm}
                member={selectedMember}
                roles={roles}
                sxoles={sxoles}
                tmimata={tmimata}
                onSubmit={handleSubmitMember}
                onCancel={() => setShowForm(false)}
            />
        </div >
    )

}

export default Members


// Componen: Action cell
function Actions({ member, onEdit, onDelete }) {
    return (
        <div>
            <Button
                variant='outline-primary'
                size='sm'
                title="Επεξεργασία Μέλους"
                onClick={() => onEdit(member)}
                style={{ marginRight: '5px' }}
            >
                <MdEdit />
            </Button>
            <Button
                variant='outline-danger'
                size='sm'
                title="Διαγραφή Μέλους"
                onClick={() => onDelete(member)}
            >
                <FaTrashAlt />
            </Button>
        </div>
    )
}
