import React from 'react'
import ReactDOM from 'react-dom/client'
import { useFormik } from 'formik'
import { Button, Form, Card, Alert } from 'react-bootstrap'
import { registerApi } from '../api/auth'
import Footer from '../layout/Footer'

const styles = {
  wrapper: {
    marginTop: '30px',
    marginBottom: '30px',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
}

export default function Registration() {

  // ---------------------------------------------------------------
  // Local state
  // ---------------------------------------------------------------
  const [error, setError] = React.useState();

  // ---------------------------------------------------------------
  // Handle submit
  // ---------------------------------------------------------------
  const handleSubmit = (values) => {
    setError(null)
    registerApi(values)
      .then(_ => {
        window.location.href = '/'
      })
      .catch(error => {
        setError(error)
      })
  }

  // ---------------------------------------------------------------
  // Form validation
  // ---------------------------------------------------------------
  const requiredFields = [
    'email', 'surname', 'firstname', 'password1', 'password2'
  ]
  const validateForm = values => {
    const errors = {}

    // Check for required fields
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = 'Υποχρεωτικό πεδίο'
      }
    })

    // email format
    if (!errors.email && !String(values.email).toLocaleLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )) {
      errors.email = "Μη έγκυρη μορφή email"
    }

    // password match
    if (!errors.password1 && !errors.password2 && values.password1 !== values.password2) {
      errors.password1 = "Οι κωδικοί δεν ταιριάζουν"
      errors.password2 = "Οι κωδικοί δεν ταιριάζουν"
    }

    return errors;
  }

  // ---------------------------------------------------------------
  // Initial form values
  // ---------------------------------------------------------------
  const initialValues = {
    email: '',
    surname: '',
    firstname: '',
    fathername: '',
    password1: '',
    password2: ''
  }

  // ---------------------------------------------------------------
  // Formik hook
  // ---------------------------------------------------------------
  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validate: validateForm,
  })


  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <div>
      <div style={styles.wrapper}>
        <div style={styles.main}>
          <img src="/img/logo.png" width={400} />
          <h2 className="mt-4">Ηλεκτρονική Υποβολή Αιτήσεων</h2>
          <Card style={{ width: 500, marginTop: 30 }}>
            <Card.Header>
              <h5>Δημιουργία Λογαριασμού</h5>
            </Card.Header>
            <Card.Body>
              {error ? <Alert variant='danger'>{error}</Alert> : null}
              <Form noValidate onSubmit={formik.handleSubmit}>

                {/* email */}
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email (*)</Form.Label>
                  <Form.Control
                    name="email"
                    type="text"
                    value={formik.values.email || ''}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.email && formik.errors.email}
                    size='sm'
                    autoFocus
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* surname */}
                <Form.Group className="mb-3" controlId="surname">
                  <Form.Label>Επώνυμο (*)</Form.Label>
                  <Form.Control
                    name="surname"
                    type="text"
                    value={formik.values.surname || ''}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.surname && formik.errors.surname}
                    size='sm'
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.surname}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* firstname */}
                <Form.Group className="mb-3" controlId="firstname">
                  <Form.Label>Όνομα (*)</Form.Label>
                  <Form.Control
                    name="firstname"
                    type="text"
                    value={formik.values.firstname || ''}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.firstname && formik.errors.firstname}
                    size='sm'
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.firstname}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* fathername */}
                <Form.Group className="mb-3" controlId="fathername">
                  <Form.Label>Πατρώνυμο</Form.Label>
                  <Form.Control
                    name="fathername"
                    type="text"
                    value={formik.values.fathername || ''}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.fathername && formik.errors.fathername}
                    size='sm'
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.fathername}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* password1 */}
                <Form.Group className="mb-3" controlId="password1">
                  <Form.Label>Κωδικός (*)</Form.Label>
                  <Form.Control
                    name="password1"
                    type="password"
                    value={formik.values.password1 || ''}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.password1 && formik.errors.password1}
                    size='sm'
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.password1}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* password2 */}
                <Form.Group className="mb-3" controlId="password2">
                  <Form.Label>Επανάληψη Κωδικού (*)</Form.Label>
                  <Form.Control
                    name="password2"
                    type="password"
                    value={formik.values.password2 || ''}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.password2 && formik.errors.password2}
                    size='sm'
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.password2}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* Submit Button */}
                <div className="d-grid gap-2 mt-4">
                  <Button variant="primary" type="submit">Δημιουργία Λογαριασμού</Button>
                </div>

              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
      <Footer />
    </div>
  )
}

// ---------------------------------------------------------------
// // Mount into dom
// ---------------------------------------------------------------
if (document.getElementById('registration')) {
  const root = ReactDOM.createRoot(document.getElementById('registration'))
  root.render(<Registration />)
}
