import React from 'react'
import PositionData from './PositionData'
import FormTabs from './FormTabs'

export default function ApplicationTypeOne() {

    // ---------------------------------------------------------------
    // JSX
    // ---------------------------------------------------------------
    return (
        <div>
            <PositionData />
            <FormTabs />
        </div>
    )
}




