import React from 'react'
import { useFormik } from 'formik'
import { Form, Modal, Button, Alert } from 'react-bootstrap'
import { checkUserByEmailApi } from '../../../../api/users'

const requiredFields = ['email', 'surname', 'firstname']
const fieldLengths = [
    {
        name: 'email',
        length: 255
    },
    {
        name: 'surname',
        length: 255
    },
    {
        name: 'firstname',
        length: 255
    },
    {
        name: 'mobil',
        length: 20
    },
    {
        name: 'afm',
        length: 9
    },
    {
        name: 'password',
        length: 20
    },
]


export default function UserModalForm({ show, user, onSubmit, onCancel }) {

    // ---------------------------------------------------------------
    // Local state and context
    // ---------------------------------------------------------------
    const [error, setError] = React.useState('')

    // ---------------------------------------------------------------
    // Reset form
    // ---------------------------------------------------------------
    React.useEffect(() => {
        formik.resetForm() // reset on close
        setError('')
    }, [show])

    // ---------------------------------------------------------------
    // Form validation
    // ---------------------------------------------------------------
    const validateForm = values => {

        const errors = {}

        // Check for required fields
        requiredFields.forEach(field => {
            if (!values[field]) {
                errors[field] = 'Υποχρεωτικό πεδίο'
            }
        })

        // Password required only on create
        if (values.id == -1 && !values.password) {
            errors.password = 'Υποχρεωτικό πεδίο'
        }

        // Check for field lengths
        fieldLengths.forEach(field => {
            if (values[field.name] && values[field.name].length > field.length) {
                errors[field.name] = `Πληκτρολογείστε μέχρι ${field.length} χαρακτήρες`
            }
        })

        // email format validation
        if (!errors.email && !String(values.email).toLocaleLowerCase().match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
            errors.email = "Μη έγκυρη μορφή email"
        }

        return errors;
    }

    // ---------------------------------------------------------------
    // Initial form values
    // ---------------------------------------------------------------
    const initialValues = {
        id: user?.id || -1,
        email: user?.email || '',
        surname: user?.surname || '',
        firstname: user?.firstname || '',
        fathername: user?.fathername || '',
        mobil: user?.mobil || '',
        afm: user?.afm || '',
        password: '',       // Πάντα κενό
        isactive: !user ? true : user.isactive,
        is_admin: user?.is_admin || false,
        is_editor: user?.is_editor || false,
        is_supervisor: user?.is_supervisor || false,
    }

    // ---------------------------------------------------------------
    // Πριν καλέσουμε την props.onSubmit ελέγχουμε αν το email υπάρχει
    // ---------------------------------------------------------------
    const handleSubmit = values => {
        checkUserByEmailApi(values.email)
            .then(response => {
                if (response && response.id != user?.id) {
                    setError('Υπάρχει ήδη χρήστης με αυτό το email');
                }
                else {
                    // Ολα ΟΚ - call props.onSubmit
                    onSubmit(values)
                }
            })
            .catch(error => console.log(error))
    }

    // ---------------------------------------------------------------
    // Formik hook
    // ---------------------------------------------------------------
    const formik = useFormik({
        initialValues,
        onSubmit: handleSubmit,
        validate: validateForm,
        enableReinitialize: true,
    })


    // ---------------------------------------------------------------
    // JSX
    // ---------------------------------------------------------------
    return (
        <Modal show={show} backdrop="static" keyboard={false} size="lg" >
            <Modal.Header>
                <Modal.Title>{user ? "Επεξεργασία Χρήστη" : "Νέος Χρήστης"}</Modal.Title>
            </Modal.Header >
            <Modal.Body>
                <Form noValidate onSubmit={formik.handleSubmit}>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <IsActiveField formik={formik} />
                    <EmailField formik={formik} />
                    <SurnameField formik={formik} />
                    <FirstnameField formik={formik} />
                    <FathernameField formik={formik} />
                    {
                        user?.id == -1 && <PasswordField formik={formik} />
                    }
                    <MobilField formik={formik} />
                    <AfmField formik={formik} />
                    <Form.Group className="mb-3" controlId="is_admin">
                        <Form.Label>ΡΟΛΟΙ</Form.Label>
                        <div style={{ display: 'flex', gap: 20 }}>
                            <IsAdminField formik={formik} />
                            <IsEditorField formik={formik} />
                            <IsSupervisorField formik={formik} />
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={formik.handleSubmit}>Καταχώρηση</Button>
                <Button variant="danger" onClick={onCancel}>Ακύρωση</Button>
            </Modal.Footer>
        </Modal>
    )
}

// ********************************************************************************
// Field: isactive
// ********************************************************************************
function IsActiveField({ formik }) {
    return (
        <Form.Group className="mb-3" controlId="isactive">
            <Form.Label>ΕΝΕΡΓΟΣ</Form.Label>
            <Form.Check
                type="switch"
                id="isactive"
                name="isactive"
                checked={formik.values.isactive || false}
                onChange={formik.handleChange}
                isInvalid={formik.touched.isactive && formik.errors.isactive}
            />
        </Form.Group>
    )
}

// ********************************************************************************
// Field: email
// ********************************************************************************
function EmailField({ formik }) {
    return (
        <Form.Group className="mb-3" controlId="email">
            <Form.Label>E-MAIL (*)</Form.Label>
            <Form.Control
                size='sm'
                name="email"
                type="text"
                value={formik.values.email || ''}
                onChange={formik.handleChange}
                isInvalid={formik.touched.email && formik.errors.email}
            />
            <Form.Control.Feedback type="invalid">
                {formik.errors.email}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

// ********************************************************************************
// Field: surname
// ********************************************************************************
function SurnameField({ formik }) {
    return (
        <Form.Group className="mb-3" controlId="surname">
            <Form.Label>ΕΠΩΝΥΜΟ (*)</Form.Label>
            <Form.Control
                size='sm'
                name="surname"
                type="text"
                value={formik.values.surname || ''}
                onChange={formik.handleChange}
                isInvalid={formik.touched.surname && formik.errors.surname}
            />
            <Form.Control.Feedback type="invalid">
                {formik.errors.surname}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

// ********************************************************************************
// Field: firstname
// ********************************************************************************
function FirstnameField({ formik }) {
    return (
        <Form.Group className="mb-3" controlId="firstname">
            <Form.Label>ΟΝΟΜΑ (*)</Form.Label>
            <Form.Control
                size='sm'
                name="firstname"
                type="text"
                value={formik.values.firstname || ''}
                onChange={formik.handleChange}
                isInvalid={formik.touched.firstname && formik.errors.firstname}
            />
            <Form.Control.Feedback type="invalid">
                {formik.errors.firstname}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

// ********************************************************************************
// Field: fathername
// ********************************************************************************
function FathernameField({ formik }) {
    return (
        <Form.Group className="mb-3" controlId="fathername">
            <Form.Label>ΠΑΤΡΩΝΥΜΟ</Form.Label>
            <Form.Control
                size='sm'
                name="fathername"
                type="text"
                value={formik.values.fathername || ''}
                onChange={formik.handleChange}
                isInvalid={formik.touched.fathername && formik.errors.fathername}
            />
            <Form.Control.Feedback type="invalid">
                {formik.errors.fathername}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

// ********************************************************************************
// Field: mobil
// ********************************************************************************
function MobilField({ formik }) {
    return (
        <Form.Group className="mb-3" controlId="mobil">
            <Form.Label>ΚΙΝΗΤΟ ΤΗΛΕΦΩΝΟ</Form.Label>
            <Form.Control
                size='sm'
                name="mobil"
                type="text"
                value={formik.values.mobil || ''}
                onChange={formik.handleChange}
                isInvalid={formik.touched.mobil && formik.errors.mobil}
            />
            <Form.Control.Feedback type="invalid">
                {formik.errors.mobil}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

// ********************************************************************************
// Field: afm
// ********************************************************************************
function AfmField({ formik }) {
    return (
        <Form.Group className="mb-3" controlId="afm">
            <Form.Label>Α.Φ.Μ.</Form.Label>
            <Form.Control
                size='sm'
                name="afm"
                type="text"
                value={formik.values.afm || ''}
                onChange={formik.handleChange}
                isInvalid={formik.touched.afm && formik.errors.afm}
            />
            <Form.Control.Feedback type="invalid">
                {formik.errors.afm}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

// ********************************************************************************
// Field: password
// ********************************************************************************
function PasswordField({ formik }) {
    return (
        <Form.Group className="mb-3" controlId="password">
            <Form.Label>ΚΩΔΙΚΟΣ ΠΡΟΣΒΑΣΗΣ (*)</Form.Label>
            <Form.Control
                size='sm'
                name="password"
                type="text"
                value={formik.values.password || ''}
                onChange={formik.handleChange}
                isInvalid={formik.touched.password && formik.errors.password}
            />
            <Form.Control.Feedback type="invalid">
                {formik.errors.password}
            </Form.Control.Feedback>
        </Form.Group>
    )
}

// ********************************************************************************
// Field: is_admin
// ********************************************************************************
function IsAdminField({ formik }) {
    return (
        <Form.Check
            label="ΔΙΑΧΕΙΡΙΣΤΗΣ"
            type="checkbox"
            id="is_admin"
            name="is_admin"
            checked={formik.values.is_admin || false}
            onChange={formik.handleChange}
            isInvalid={formik.touched.is_admin && formik.errors.is_admin}
        />
    )
}

// ********************************************************************************
// Field: is_editor
// ********************************************************************************
function IsEditorField({ formik }) {
    return (
        <Form.Check
            label="ΣΥΝΤΑΚΤΗΣ"
            type="checkbox"
            id="is_editor"
            name="is_editor"
            checked={formik.values.is_editor || false}
            onChange={formik.handleChange}
            isInvalid={formik.touched.is_editor && formik.errors.is_editor}
        />
    )
}

// ********************************************************************************
// Field: is_supervisor
// ********************************************************************************
function IsSupervisorField({ formik }) {
    return (
        <Form.Check
            type="checkbox"
            label="ΕΠΟΠΤΗΣ"
            id="is_supervisor"
            name="is_supervisor"
            checked={formik.values.is_supervisor || false}
            onChange={formik.handleChange}
            isInvalid={formik.touched.is_supervisor && formik.errors.is_supervisor}
        />
    )
}


