import React from 'react'
import { Container } from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import ReactDOM from 'react-dom/client'
import { fetchUserApi } from '../../../../api/users'
import Header from './Header'
import Stoixeia from './Stoixeia'
import InvitationsAsMember from './InvitationsAsMember'
import Applications from './Applications'
import useLoader from '../../../../hooks/useLoader'

export default function Show() {

  // ---------------------------------------------------------------
  // Local state and context
  // ---------------------------------------------------------------
  const [user, setUser] = React.useState()
  const { loader: pageLoader, startLoader: startPageLoader, stopLoader: stopPageLoader } = useLoader()

  // ---------------------------------------------------------------
  // Get user id from url and fetch it
  // ---------------------------------------------------------------
  React.useEffect(() => {

    startPageLoader()

    const url = window.location.href.split('/')
    const userId = url[url.length - 1]

    // Fetch User
    fetchUserApi(userId)
      .then(data => {
        setUser(data)
        stopPageLoader()
      })
      .catch(error => {
        console.log(error)
        stopPageLoader()
      })

  }, [])

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <Container className="mt-3 mb-5">
      {
        pageLoader ||
        <div>
          <Header user={user} />
          <Tabs className="mt-3">
            <TabList>
              <Tab>Στοιχεία Χρήστη</Tab>
              <Tab>Εποπτευόμενες Προσκλήσεις</Tab>
              <Tab>Αιτήσεις</Tab>
            </TabList>
            <TabPanel>
              <Stoixeia user={user} setUser={setUser} />
            </TabPanel>
            <TabPanel>
              <InvitationsAsMember user={user} />
            </TabPanel>
            <TabPanel>
              <Applications user={user} />
            </TabPanel>
          </Tabs>
        </div>
      }
    </Container>
  )
}


// ---------------------------------------------------------------
// Mount into dom
// ---------------------------------------------------------------
if (document.getElementById('admin-users-show')) {
  const root = ReactDOM.createRoot(document.getElementById('admin-users-show'))
  root.render(<Show />)
}