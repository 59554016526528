// /applications/edit/application_id
import React from 'react'
import ReactDOM from 'react-dom/client'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { fetchApplicationApi } from '../../api/applications'
import InvitationHeader from '../../components/InvitationHeader'
import ApplicationFormTypeOne from '../../components/TypeOne/Application/ApplicationTypeOne'
import useLoader from '../../hooks/useLoader'

export const ApplicationEditContext = React.createContext();

export default function ApplicationEdit() {

  // ---------------------------------------------------------------
  // Local state
  // ---------------------------------------------------------------
  const [application, setApplication] = React.useState()
  const { loader: pageLoader, startLoader: startPageLoader, stopLoader: stopPageLoader } = useLoader()

  // ---------------------------------------------------------------
  // Fetch application on mount 
  // /applications/{id}/edit
  // ---------------------------------------------------------------
  React.useEffect(() => {

    startPageLoader()

    const url = window.location.href.split('/')
    const applicationId = url[url.length - 2]
    fetchApplicationApi(applicationId)
      .then(data => {
        setApplication(data)
        stopPageLoader()
      })
      .catch(error => {
        toast.error(error)
        stopPageLoader()
      })
  }, [])

  // ---------------------------------------------------------------
  // JSX
  // ---------------------------------------------------------------
  return (
    <div>
      {
        pageLoader ||
        <div>
          <ToastContainer position={toast.POSITION.TOP_CENTER} theme="colored" />
          <h4 style={{ textAlign: 'center', color: '#429325', marginBottom: '20px' }}>ΕΠΕΞΕΡΓΑΣΙΑ ΑΙΤΗΣΗΣ</h4>
          <InvitationHeader invitation={application?.invitation} />
          <ApplicationEditContext.Provider value={[application, setApplication]}>
            <ApplicationFormTypeOne />
          </ApplicationEditContext.Provider>
        </div>}
    </div >
  )
}



// ---------------------------------------------------------------
// Mount into dom
// ---------------------------------------------------------------
if (document.getElementById('applications-edit')) {
  const root = ReactDOM.createRoot(document.getElementById('applications-edit'))
  root.render(<ApplicationEdit />)
}