import axios from "axios"

// Fetch invitations (depends on user role)
// Applican invitations list (open)
export function fetchInvitationsApi() {
    return new Promise((resolve, reject) => {
        axios.get('/api/invitations')
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                if (error.response.data.message) reject(error.response.data.message)
                else reject("Σφάλμα κατά την ανάκτηση των προσκλήσεων")

            })
    })
}

// fetch specific invitation
export function fetchInvitationApi(invitationId) {
    return new Promise((resolve, reject) => {
        axios.get(`/api/invitations/${invitationId}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                if (error.response.data.message) reject(error.response.data.message)
                else reject("Σφάλμα κατά την ανάκτηση της πρόσκλησης")

            })
    })
}


// post new invitation (admin only)
export function postInvitationApi(invitation) {
    return new Promise((resolve, reject) => {
        axios.post('/api/admin/invitations', invitation)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                if (error.response.data.message) reject(error.response.data.message)
                else reject("Σφάλμα κατά την αποθήκευση της νέας πρόσκλησης")

            })
    })
}

// update invitation (admin only)
export function updateInvitationApi(invitation) {
    return new Promise((resolve, reject) => {
        axios.put(`/api/admin/invitations/${invitation.id}`, invitation)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                if (error.response.data.message) reject(error.response.data.message)
                else reject("Σφάλμα κατά την αποθήκευση της πρόσκλησης")

            })
    })
}

// delete invitation (admin only)
export function deleteInvitationApi(invitation) {
    return new Promise((resolve, reject) => {
        axios.delete(`/api/admin/invitations/${invitation.id}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                if (error.response.data.message) reject(error.response.data.message)
                else reject("Σφάλμα κατά την διαγραφή της πρόσκλησης")

            })
    })
}

// Upload invitation file
export function postInvitationFileApi(fields, file) {
    var formData = new FormData()
    formData.append('invitation_id', fields.invitation_id)
    formData.append('descr', fields.descr)
    formData.append('filename', file)
    return new Promise((resolve, reject) => {
        axios.post('/api/admin/invitation-files', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject("Σφάλμα κατά την αποθήκευση του αρχείου της πρόσκλησης")
            })
    })
}

// delete invitation file
export function deleteInvitationFileApi(invitationFile) {
    return new Promise((resolve, reject) => {
        axios.delete(`/api/admin/invitation-files/${invitationFile.id}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject("Σφάλμα κατά την διαγραφή του αρχείου της πρόσκλησης")
            })
    })
}

// store new invitation document (admin only)
export function createInvitationDocumentApi(document) {
    return new Promise((resolve, reject) => {
        axios.post("/api/admin/invitation-documents", document)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                if (error.response.data.message) reject(error.response.data.message)
                else reject("Σφάλμα κατά την δημιουργία του δικαιολογητικού")

            })
    })
}

// update invitation document (admin only)
export function updateInvitationDocumentApi(document) {
    return new Promise((resolve, reject) => {
        axios.put(`/api/admin/invitation-documents/${document.id}`, document)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                if (error.response.data.message) reject(error.response.data.message)
                else reject("Σφάλμα κατά την ενημέρωση του δικαιολογητικού")

            })
    })
}

// delete invitation document
export function deleteInvitationDocumentApi(document) {
    return new Promise((resolve, reject) => {
        axios.delete(`/api/admin/invitation-documents/${document.id}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject("Σφάλμα κατά την διαγραφή του δικαιολογητικού της πρόσκλησης")
            })
    })
}

// Create new Invitation position 
export function createPositionApi(position) {
    return new Promise((resolve, reject) => {
        axios.post("/api/admin/positions", position)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                if (error.response.data.message) reject(error.response.data.message)
                else reject("Σφάλμα κατά την δημιουργία της θέσης")

            })
    })
}

// update invitation position 
export function updatePositionApi(position) {
    return new Promise((resolve, reject) => {
        axios.put(`/api/admin/positions/${position.id}`, position)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                if (error.response.data.message) reject(error.response.data.message)
                else reject("Σφάλμα κατά την ενημέρωση της θέσης")

            })
    })
}

// delete invitation position
export function deletePositionApi(position) {
    return new Promise((resolve, reject) => {
        axios.delete(`/api/admin/positions/${position.id}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                if (error.response.data.message) reject(error.response.data.message)
                else reject("Σφάλμα κατά την διαγραφή της θέσης")
            })
    })
}

// Send emails to all members of invitations with a list of applications
export function sendMailToMembersApi(invitation) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/admin/invitations/${invitation.id}/send-emails`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                if (error.response.data.message) reject(error.response.data.message)
                else reject("Σφάλμα κατά την αποστολή emails στα μέλη της πρόσκλησης")

            })
    })
}